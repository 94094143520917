import React, { useState } from 'react';
import GalleryImageSlider from './GalleryImageSlider';
import GalleryVideoSlider from './GalleryVideoSlider'; 
import { imageUrls } from "../constants/galleryImages";
import { videoUrls } from "../constants/galleryVideos";



const NavBar = ({ activeTab, onTabChange }) => {
    return (
      <div className="row mb-2 img-video">
      <p>
        <button className={activeTab === 'images' ? 'img active' : 'img'} onClick={() => onTabChange('images')}>IMAGES</button>
        <span>/</span>
        <button className={activeTab === 'videos' ? 'video active' : 'video'} onClick={() => onTabChange('videos')}>VIDEOS</button>
      </p>
    </div>
    );
  };
  

const GalleryNavbar = () => {
  const [activeTab, setActiveTab] = useState('images');
  const [images, setImages] = useState([]); 
  const [videos, setVideos] = useState([]); 

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'images') {
      setImages(imageUrls); 
    } else if (tab === 'videos') {
      setVideos(videoUrls); 
    }
  };

  return (
    <div className='gallery-nav'>
      <NavBar activeTab={activeTab} onTabChange={handleTabChange} />
      {activeTab === 'images' && <GalleryImageSlider images={images} />}
      {activeTab === 'videos' && <GalleryVideoSlider videos={videos} />}
    </div>
  );
};

export default GalleryNavbar;
