import React from "react";
import CommonSection from "../common-componets/CommonSection";


const Contact = () => {


  return (

    <>

      <CommonSection className="page-not-found full-height d-flex flex-column align-items-center justify-content-center w-100  text-center">
       <div className="nopage full-height d-flex flex-column align-items-center justify-content-center w-100  text-center">
        <h4>Oops!</h4> 
          <h3 className="mt-2">Page under construction</h3>
       </div>
      
      </CommonSection>
    </>

  )
};

export default Contact;
