import React from 'react'
import "./base.css";
import CommonSection from '../common-componets/CommonSection';
import { useState } from 'react';
import {  Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


const ShopSub = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = ['Relevance', 'Relevance', 'Relevance'];

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };


  const sec3Data = [
   {
      image_url: "/images/shop/sec-3/7.png",
      title: "Pobel bekurade",
      description: "Belingar närvaropeng",
      image_url2: "/images/star.svg",
      price: "￡10.00"
   },
   {
       image_url: "/images/shop/sec-3/3.png",
       title: "Pobel bekurade",
       description: "Belingar närvaropeng",
       image_url2: "/images/star.svg",
       price: "￡10.00"
    },
    {
       image_url: "/images/shop/sec-3/1.png",
       title: "Pobel bekurade",
       description: "Belingar närvaropeng",
       image_url2: "/images/star.svg",
       price: "￡10.00"
    },
    {
       image_url: "/images/shop/sec-3/2.png",
       title: "Pobel bekurade",
       description: "Belingar närvaropeng",
       image_url2: "/images/star.svg",
       price: "￡10.00"
    }
   ]
   const sec3mdData = [
    {
       image_url: "/images/shop/sec-3/7.png",
       title: "Pobel bekurade",
       description: "Belingar närvaropeng",
       image_url2: "/images/star.svg",
       price: "￡10.00"
    },
    {
        image_url: "/images/shop/sec-3/3.png",
        title: "Pobel bekurade",
        description: "Belingar närvaropeng",
        image_url2: "/images/star.svg",
        price: "￡10.00"
     }
    ]
   const sec33Data = [
      {
         image_url: "/images/shop/sec-3/3.png",
         title: "Pobel bekurade",
         description: "Belingar närvaropeng",
         image_url2: "/images/star.svg",
         price: "￡10.00"
      },
      {
          image_url: "/images/shop/sec-3/4.png",
          title: "Pobel bekurade",
          description: "Belingar närvaropeng",
          image_url2: "/images/star.svg",
          price: "￡7.000.000"
       },
       {
          image_url: "/images/shop/sec-3/5.png",
          title: "Pobel bekurade",
          description: "Belingar närvaropeng",
          image_url2: "/images/star.svg",
          price: "￡10.00"
       },
       {
          image_url: "/images/shop/sec-3/6.png",
          title: "Pobel bekurade",
          description: "Belingar närvaropeng",
          image_url2: "/images/star.svg",
          price: "￡7.000.000"
       }
      ]
      const sec33mdData = [
        {
           image_url: "/images/shop/sec-3/3.png",
           title: "Pobel bekurade",
           description: "Belingar närvaropeng",
           image_url2: "/images/star.svg",
           price: "￡10.00"
        },
        {
            image_url: "/images/shop/sec-3/4.png",
            title: "Pobel bekurade",
            description: "Belingar närvaropeng",
            image_url2: "/images/star.svg",
            price: "￡7.000.000"
         }
        ]
  

  return (
    <>
   
    <div className="container-fluid shopSub-banner-container " style={{
        backgroundImage: 'url(/images/shop_sub/shop_sub.png)',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        WebkitBackgroundSize: "cover",
        MozBackgroundSize: "cover",
        OBackgroundSize: "cover",
     }} >
        <div className=" shopSub-banner container flex-column d-flex justify-content-center pt-5" >
            <div className="row mt-5">
                <div className="col-12 mt-5">
                    <h1 className="mt-lg-5">Terrakrati <span>stuprörspolitik <br></br>plament</span></h1>
                </div>
            </div>
        </div>
    </div>
    <CommonSection className={' shopSub-sec-2'}>
      <div className='container'>
         <div className='row d-flex pt-4 pb-3'>
            <div className='col-1  d-flex flex-end justify-content-end align-item-center'>
               <img src="/images/filter.svg" alt=""></img>
            </div>
            <div className='col-1 me-lg-2 d-flex align-item-center'>
               <p className='filter'>Filter</p>
            </div>
            <div className='col-1 hidden d-flex align-item-center'>
               <img src="/images/dots.svg" alt=""></img>
            </div>
            <div className='col-1 hidden d-flex align-item-center'>
               <img src="/images/list.svg" alt=""></img>
            </div>
            <div className='col-5'>
            </div>
            <div className='col-2  d-flex align-item-center'>
               <p className='sort'>Sort by</p>
            </div>
            <div className='col-2 '>
                    <DropdownButton id="dropdown-toggle1" title="Relevance">
                        <Dropdown.Item href="/nopage" id="dropdown-menu">Relevance</Dropdown.Item>
                        <Dropdown.Item href="/nopage" id="dropdown-menu">Relevance</Dropdown.Item>
                        <Dropdown.Item href="/nopage" id="dropdown-menu">Relevance</Dropdown.Item>
                     </DropdownButton>
            </div>

         </div>
      </div>
    </CommonSection>

    <CommonSection className={"shop pt-4 pb-5 hidden-md"}>
      <div className='container flex-column d-flex align-items-center justify-content-center shop-sec-3 mt-5 mb-5'>
      <div className='row'>
                <div className='col-12 mb-5'>
                    <h2 >Featured Products</h2>
                </div>
            </div>
            <div className="d-lg-flex w-100 align-items-center justify-content-center">
                {sec3Data.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn '>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })} 
            </div> 
                <div className="d-lg-flex w-100 mt-2 align-items-center justify-content-center">
                {sec33Data.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                        <div className='tag2'>
                                    <h2>NEW</h2>
                                </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className="d-lg-flex w-100 mt-2 hidden align-items-center justify-content-center">
                {sec3Data.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn '>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className="d-lg-flex hidden w-100 align-items-center justify-content-center">
                {sec33Data.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                        <div className='tag2'>
                                    <h2>NEW</h2>
                                </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn '>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className="d-lg-flex hidden w-100 align-items-center justify-content-center">
                {sec3Data.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn '>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className='row  '>
                <div className='col-12'>
                    <button className='btn btn-primary mx-auto'>Shop more</button>
                </div>
            </div>
        </div>
    </CommonSection>

{/* medium screen */}

<CommonSection className={"shop pt-4 pb-5 hidden-lg hidden"}>
      <div className='container flex-column d-flex shop-sec-3 mt-5 mb-5'>
      <div className='row'>
                <div className='col-12 mb-3'>
                    <h2 >Featured Products</h2>
                </div>
            </div>
            <div className="d-flex w-100 mb-5 align-items-center justify-content-center">
                {sec3mdData.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn mt-5'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })} 
            </div> 
                <div className="d-flex w-100 mt-5 mb-5 align-items-center justify-content-center">
                {sec33mdData.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                        <div className='tag2'>
                                    <h2>NEW</h2>
                                </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn mt-5'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className="d-flex w-100 mt-5 mb-5 hidden align-items-center justify-content-center">
                {sec3mdData.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn mt-5'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className="d-flex hidden w-100 mt-5 mb-5 align-items-center justify-content-center">
                {sec33mdData.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                        <div className='tag2'>
                                    <h2>NEW</h2>
                                </div>
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn mt-5'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className="d-flex hidden w-100 mt-5 mb-5 align-items-center justify-content-center">
                {sec3mdData.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className='row mt-4 '>
                <div className='col-12'>
                    <button className='btn btn-primary mx-auto'>Shop more</button>
                </div>
            </div>
        </div>
    </CommonSection>


    <CommonSection className={'shopSub-sec-4 pt-4 pb-4'}>
      <div className='container flex-column d-flex pt-4'>
         <div className='row '>
            <div className='col-1'>
                  <img src="/images/trophy 1.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>High Quality</h6>
               <p>crafted from top materials</p>
            </div>
            <div className='col-1'>
                  <img src="/images/guarantee.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>Warranty Protection</h6>
               <p>over 2 years</p>
            </div>
            <div className='col-1'>
                  <img src="/images/shipping.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>Free Shipping</h6>
               <p>Order over 150 $</p>
            </div>
            <div className='col-1'>
                  <img src="/images/customer-support.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>24 / 7 Support</h6>
               <p>Dedicated support</p>
            </div>      
         </div>
      </div>
    </CommonSection>






    
    <CommonSection className="container home-sec-6">
            <div className="row align-items-center flex-column">
               <div className="col-12 d-flex justify-content-lg-center  justify-content-md-center  mt-5 mb-3">
                     <h1 >Travel<span>.</span> Capture<span>.</span> Share<span>.</span></h1>
               </div>
               <div className="col-12 d-flex  justify-content-lg-center  justify-content-md-center justify-content-sm-start  mb-2">
                  <p>The world is our playground, and every adventure with Travelista will be an unforgettable chapter in your travel story. </p>
               </div>
               <div className="col-12 d-flex  justify-content-lg-center  justify-content-md-center justify-content-sm-start  mt-2 mb-lg-5">
                  <button className="btn btn-primary me-2 mb-3 travel-btn">Plan your Travel</button>
                  <button className="btn btn-primary button">👋  Contact</button>
               </div>
            </div>
         </CommonSection>
    </>
  )
}

export default ShopSub
