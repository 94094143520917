import React, { useState,useEffect } from 'react'
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import ScrollToTop from '../ScrollToTop';
import './Destination.css'
import BlogSlider from '../Home/HomeBlogSlider';
import TextTransition from './TextTransition';


const DestinationAfrica = () => {
   
   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }
   const scrollTop = () =>{
      window.scrollTo(0,0);
   }

   const sec3Data = [
      {
         image_url: "/images/destination/2.png",
          title: "ZIMBABWE",
          description: "Gold Mining Plant",
          link:"https://youtu.be/D2iW6NF9CB0?si=E_8O-0HcB5iWtrjy"
      },
      {
         image_url: "/images/destination/3.png",
         title: "ZAMBIA",
         description: "Heartbreaking Realities Of Life",
         link:"https://youtu.be/nby4DbZ_UkA?si=nb5cxWH3lCsxuIRT"
      }
    ]
    const sec3SlideSettings = {
      dots: false,
      infinite: true,
      autoplay: false,
      // autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 3
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:2
            }
         },
         {
           breakpoint: 576,
           settings: {
              slidesToShow:1
           }
        }
      ],
      arrows:false
   };


  const sec5Data = [
    {
       image_url: "/images/africa/sec-5/1.png",
       title: "ZAMBIA",
       description: "World’s Largest Emarald Mine",
       link:"https://youtu.be/QPSIlRF3o2c?si=6a62ZRJ5PRTDPmHm"
    },
    {
      image_url: "/images/africa/sec-5/2.png",
       title: "KENYA",
       description: "Lion Hunting",
       link:"https://youtu.be/znYP7VKyKEA?si=FQOMosVmJsrlixVA"
    },
    {
      image_url: "/images/africa/sec-5/3.png",
      title: "JOHANNESBURG",
      description: "Dangerous Place in Africa",
      link:"https://youtu.be/blhpuPKB8GM?si=AX7UcFAeSw7eE4eK"
   },
      {
      image_url: "/images/africa/sec-5/4.png",
      title: "ZIMBABWE",
      description: "Esibomvu Tribal Village",
      link:"https://youtu.be/l_9OLgITJk4?si=-eQDznLcrsDyBjfa"
   },
   {
      image_url: "/images/africa/sec-5/5.png",
      title: "ZAMBIA ",
      description: "Garden Route",
      link:"https://youtu.be/YA8xWEBjCaw?si=Ux3110hy0F7dtDvs "
   }

  ]

  // Custom previous and next arrows
   const PrevArrow = (props) => {
      const { className, onClick } = props;
      return (
      <div className={`custom-prev-arrow ${className}`} onClick={onClick}>
         Previous
      </div>
      );
   };
   
   const NextArrow = (props) => {
      const { className, onClick } = props;
      return (
      <div className={`custom-next-arrow ${className}`} onClick={onClick}>
         Next
      </div>
      );
   };
   
   const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow: 1
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ]
 };
 //  BANNER EFFECT
 const [scrollOpacity, setScrollOpacity] = useState(1);
 const [scrollScale, setScrollScale] = useState(1);

 useEffect(() => {
   const handleScroll = () => {
     const scrollPosition = window.scrollY;
     const maxOpacityScroll = 400; 
     const maxZoomScroll = 800; 

     // Calculate opacity based on scroll position
     const opacity = 1 - (scrollPosition / maxOpacityScroll);
     setScrollOpacity(opacity < 0 ? 0 : opacity);

     // Calculate scale based on scroll position
     const scale = 1 + (scrollPosition / maxZoomScroll) * 0.2; 
     setScrollScale(scale);
   };

   window.addEventListener('scroll', handleScroll);
   return () => window.removeEventListener('scroll', handleScroll);
 }, []);
 
   useEffect(() => {
     const images = document.querySelectorAll('.dest-section-1 img');
     images.forEach(img => {
       img.style.opacity = scrollOpacity;
       img.style.transform = `scale(${scrollScale})`;
     });
   }, [scrollOpacity, scrollScale]);

  return (
    <>
       {/* SECTION-1 */}
       <div class="dest-section-1 img_container top_img">
         <img className='hidden hidden-md' src="/images/africa/banner/1.png" alt=''/>
          <img className='hidden-lg hidden' src="/images/africa/banner/1-md.png"  alt='' />
          <img className='hidden-lg hidden-md' src="/images/africa/banner/1-sm.png"  alt=''/>
          <div class="banner-content" >
            <h1 >TRAVEL IN<br/><span>Africa</span></h1>
            <p className='text-white text-align-justify'>Explore Africa's stunning beauty and rich culture on an amazing journey through its different landscapes and lively communities.
            </p>
            <Link to="/contact" onClick={scrollTop}><button className="btn btn-primary destination-contact"> 
                  Contact Us
                  
            </button></Link>
          </div>
      </div>

      <div class='dest-section-4'>
         <img className='sec2-img hidden hidden-md' src='/images/africa/sec-4/1.png' />
         <img className='sec2-img hidden-lg hidden' src='/images/africa/sec-4/1-md.png' />
         <img className='sec2-img hidden-lg hidden-md' src='/images/africa/sec-4/1-sm.png' />
         <div className='sec-title'>
            <div className='yellowline'></div>
            <h1 className="hidden-md hidden">EXPLORING AFRICA'S<br/>WILD HEART</h1>
            <h1 className="hidden-lg">EXPLORING AFRICA'S <br/> WILD HEART</h1>
            <p>
            Our journey begins amidst the sweeping plains of the Maasai Mara, where the rhythm of the African wilderness pulses at every step. Traverse this magnificent landscape aboard rugged jeeps led by seasoned guides who share their profound knowledge of the region's flora and fauna.  <br></br><br></br>
                  Witness the breathtaking event of the Great Migration, where thousands of wildebeests and zebras embark on a remarkable journey across the savannas, braving the crocodile-infested rivers in a quest for fresh grazing lands. Come, be a part of this extraordinary journey, and let Africa's wild heart captivate your soul. 
                  </p>           
         </div>
      </div>
      
      <div class="dest-section-2 main-section">
         <div className='sec-title'>
            <h1 className="">DISCOVER AFRICAN<br></br>Journey Through<br/>My Vlogs </h1>
            <div className='blackline'></div>
         </div>
         
         <div className='sec-card'>
            <Link to="https://youtu.be/D2iW6NF9CB0?si=E_8O-0HcB5iWtrjy" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md ' src='/images/africa/sec-2/1.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/africa/sec-2/1-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/africa/sec-2/1-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">ZIMBABWE</div>
                  <div className="description">Gold Mining Plant</div>
                  <div className="line"></div>
               </div>
            </Link>
            <Link to="https://youtu.be/nby4DbZ_UkA?si=nb5cxWH3lCsxuIRT" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md' src='/images/africa/sec-2/2.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/africa/sec-2/2-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/africa/sec-2/2-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">ZAMBIA</div>
                  <div className="description">Heartbreaking Realities Of Life</div>
                  <div className="line"></div>
               </div>
            </Link>
         </div>
         
      </div>

      <div class='dest-section-3'>
         <div className='sec-content'>
           <TextTransition text="Immersed in the heart of Africa, surrounded by warmth, culture, and an unspoken connection that transcends borders. Moments like these, among the vibrant tapestry of local life, remind me why exploring the world is an endless journey of discovery">
            </TextTransition> 
         </div>
      </div>

      

      {/* CARD */}
      <div className="home-sec-5" style={{
               backgroundImage: 'url(/images/Blog-bg/africa-blog-bg.png)',
               marginBottom: '1rem',
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               WebkitBackgroundSize: "cover",
               MozBackgroundSize: "cover",
               OBackgroundSize: "cover",
               paddingTop:"6rem"
            
            }}>

         <div className="slide-container">
            <div className="row sec-title mt-5 mb-5 hidden-lg hidden-md" >
               <div className="col-12">
                  <h2 className="yellow-line mt-5" style={{color:"#000"}}>EXPLORE MORE<br/>AFRICAN<br/>ADVENTURES</h2>
               </div>
            </div>
            <BlogSlider secData={sec5Data} 
               secTitle={( <> EXPLORE MORE<br/>AFRICAN<br className="web-view-break"/>ADVENTURES </> )} 
               secSettings={sec5SlideSettings}></BlogSlider>
         </div>

         
         
      </div>
      


    </>
  )
}

export default DestinationAfrica
