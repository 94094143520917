import React from 'react'
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import "./SecSlider.css"
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const SecSlider = (props) => {
  return (
    <div className='admedia slider-outer'>
      <Slider {...props.secSettings} className='slide'>
        {props.secData.map((item, index) => (
          <div id="" className= "">
            {/* <Link to={item.link} target="_blank">  */}
                <div className="slidecard" style={{ backgroundImage: `url(${item.image_url}`,  backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
                    <div className="box">
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    </div>   
                </div>
            {/* </Link>  */}
        </div>
        ))}
      </Slider>
    </div>
  )
}

export default SecSlider;