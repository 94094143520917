import React  from "react";
import CommonSection from "../common-componets/CommonSection";
import "./base.css";
import { useEffect, useState, useRef } from "react";
import $ from 'jquery';
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import ScrollToTop from "../common-componets/ScrollToTop";
import HomeGallery from "../common-componets/Home/HomeGallery";
import BlogSlider from "../common-componets/Home/HomeBlogSlider";
import Banner from "../common-componets/Home/Banner";
import { sec2Data } from "../constants/HomeSec2";

const Home = () => {

   


   // AUTOPLAY ANIMATION
   const videoRef = useRef(null);

   // useEffect(() => {
   //    // When the component mounts, play the video
   //    if (videoRef.current) {
   //       videoRef.current.play();
   //    }
   // }, []);

   // const [tabSelected, setTabSelected] = useState(0);
   // const [expandSelected, setExpandSelected] = useState(null)

   
   const sec4Data = [
      {
         image_url: "/images/app-home/sec4/thumbnail/1.png",
         image: "/images/app-home/sec4/img/1.png",
         title: "",
         description: "",

      },
      {
         image_url: "/images/app-home/sec4/thumbnail/2.png",
         image: "/images/app-home/sec4/img/2.png",
         title: "",
         description: "",

      },
      {
         image_url: "/images/app-home/sec4/thumbnail/3.png",
         image: "/images/app-home/sec4/img/3.png",
         title: "",
         description: "",

      },
      {
         image_url: "/images/app-home/sec4/thumbnail/4.png",
         image: "/images/app-home/sec4/img/4.png",
         title: "",
         description: "",

      },
      {
         image_url: "/images/app-home/sec4/thumbnail/5.png",
         image: "/images/app-home/sec4/img/5.png",
         title: "",
         description: "",

      },
      {
         image_url: "/images/app-home/sec4/thumbnail/6.png",
         image: "/images/app-home/sec4/img/6.png",
         title: "",
         description: "",

      },
      {
         image_url: "/images/app-home/sec4/thumbnail/7.png",
         image: "/images/app-home/sec4/img/7.png",
         title: "",
         description: "",

      },
      {
         image_url: "/images/app-home/sec4/thumbnail/8.png",
         image: "/images/app-home/sec4/img/8.png",
         title: "",
         description: "",

      },
      {
         image_url: "/images/app-home/sec4/thumbnail/9.png",
         image: "/images/app-home/sec4/img/9.png",
         title: "MORE",
         description: "",

      },
     
     
   ]

   
   

   const [blogData] = useState([
      {
         date: "28",
         month: "Nov",
         year: "2023",
         text: "Hidden Gems: Uncovering Off-the-Beaten-Path Destinations ",
         image_url:'/images/app-home/blog/1.png'
      },
      {
         date: "24",
         month: "Nov",
         year: "2023",
         text: "Solo Travel: Embracing Adventures Alone",
         image_url:'/images/app-home/blog/2.png'
      },
      {
         date: "20",
         month: "Nov",
         year: "2023",
         text: "Top 10 Family-Friendly Destinations for Memorable Vacations ",
         image_url:'/images/app-home/blog/3.png'
      },
      // {
      //    date: "22",
      //    month: "Nov",
      //    year: "2023",
      //    text: "Exploring Sustainable Tourism: Eco-Friendly Destinations and Practices ",
      //    image_url:'/images/app-home/blog/1.png'
      // },
      // {
      //    date: "17",
      //    month: "Nov",
      //    year: "2023",
      //    text: "The Ultimate Adventure Travel Bucket List: Thrills and Experiences ",
      //    image_url:'/images/app-home/blog/1.png'
      // },
      // {
      //    date: "15",
      //    month: "Nov",
      //    year: "2023",
      //    text: "Immersive Cultural Experiences: Living Like a Local Abroad ",
      //    image_url:'/images/app-home/blog/1.png'
      // },
      // {
      //    date: "12",
      //    month: "Nov",
      //    year: "2023",
      //    text: "Wellness Retreats: Finding Serenity in Nature's Havens ",
      //    image_url:'/images/app-home/blog/1.png'
      // },
      // {
      //    date: "10",
      //    month: "Nov",
      //    year: "2023",
      //    text: "Travelling on a Budget: Tips and Tricks for Affordable Adventures ",
      //    image_url:'/images/app-home/blog/1.png'
      // },
      // {
      //    date: "28",
      //    month: "Nov",
      //    year: "2023",
      //    text: "A Foodie's Guide to Culinary Delights Around the World ",
      //    image_url:'/images/app-home/blog/1.png'
      // },
      // {
      //    date: "24",
      //    month: "Nov",
      //    year: "2023",
      //    text: "Solo Travel: Embracing Adventures Alone",
      //    image_url:'/images/app-home/blog/1.png'
      // },
      
   ]);

   // MAKE ARRAY OF 3 BLOGS FOR TAB AND MOBILE
   const numRows = Math.ceil(blogData.length / 3);
   const blogRows = Array(numRows).fill().map((_, rowIndex) => (
      blogData.slice(rowIndex * 3, rowIndex * 3 + 3)
   ));


   
   const[blogActivePosition, setBlogActivePosition]=useState(2)
   const totalBlogs = blogData.length
   const sec5SlideSettings = {
      variableWidth: true,
      dots: false,
      infinite: true,
      autoplay: false,
      center: true,
      nav:true,
      autoplaySpeed: 5000,
      speed: 2000,
      slidesToShow: 2,
      slidesToScroll: 4,
      arrows:true,

      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 2
            }
         },
         {
            breakpoint: 576,
            settings: {
               // variableWidth: true, 
               slidesToShow:1
            }
         }
      ]
   };

   const sec2SlideSettings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth:true,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 3
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:2
            }
         },
         {
            breakpoint: 576,
            settings: {
               slidesToShow:1
            }
         }
      ],
   };

   const sec4SlideSettings = {
      dots: false,
      infinite: false,
      autoplay: false,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 3
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:2
            }
         },
         {
            breakpoint: 576,
            settings: {
               slidesToShow:1
            }
         }
      ],
   };

   useEffect(() => {
      $( "#blog-scroller" ).on( "scroll", function() {
       
            if(window.innerWidth>767){
               const scrolltop=$("#blog-scroller").scrollTop()
               const scrollIndex=parseInt((scrolltop/79).toFixed())+2

            setBlogActivePosition(scrollIndex)
            }
            else{

            }
            }
        );

   }, [])
   
   const scrollDown = () =>{

      window.scrollTo(0, 640);
   //   console.log("Hello")  
}

   const scrollTop = () =>{
      window.scrollTo(0,0);
   }
   
   

   return (
      <>
         {/* BANNER */}
         
         <Banner></Banner>
         
         <CommonSection className="container-fluid home-sec-1 d-flex justify-content-center align-items-center" >
               <div className="container d-flex justify-content-center align-items-center">
                  {/* <h1>Your Travel & Media Partner<span>.</span> </h1> */}
                  <video src="/images/app-home/sec1/web.mp4" autoPlay muted loop playsInline className="logo-video hidden" ></video>
                  <video ref={videoRef} src="/images/app-home/sec1/mobile.mp4" autoPlay muted loop playsInline className="logo-video hidden-md hidden-lg" >
               </video>
               </div>
         </CommonSection>
         
         {/* POPULAR MILESTONES */}
         <CommonSection className=" container-fluid home-sec-2 home-container  popular-milestone mt-5" >

            <div className="container  justify-content-center align-items-center">
                  <div className="yellowline"></div>
                  <h2 className="title1">TOP DESTINATIONS WE COVERED </h2>
                  <p>If you want to explore these destinations with Team Travelista, 
                  <Link to="/contact" onClick={scrollTop}><span className="contactus"> contact us</span></Link> now.</p>
               <div className="product-card-slider">
                  <Slider {...sec2SlideSettings}  >
                  {sec2Data.map((item, index) => {
                     return (
                        <Link to={item.link} target="_blank">
                              <div key={index} className="slide">
                                 <div className="card " style={{backgroundImage:`url(${item.image_url})`}}>
                                    <div class="card-body">
                                       <h5 class="card-title">{item.title}</h5>
                                       <p class="card-text">{item.description}</p>
                                    </div>  
                                 </div>
                              </div> 
                           
                        </Link>
                     )
                  })}

                  </Slider>
               </div>
            </div>
        
         </CommonSection>
         

         {/* SECTION 3 VOYAGE VIBES */}
         <div className="container-fluid home-voyage" style={{
               // backgroundImage: 'url(/images/app-home/About_BG.png)',
               backgroundColor: '#fff',
               // marginBottom: '6rem',
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               WebkitBackgroundSize: "cover",
               MozBackgroundSize: "cover",
               OBackgroundSize: "cover",
               marginTop:"-7rem",
               paddingBottom:"7rem"
            
            }} >
            
            <CommonSection className="container  home-sec-3">
               <div className="row">
                  <div className="col-lg-6 d-flex justify-content-center align-items-center">
                     <div className="text-center">
                        <div className="yellowline"></div>
                        <h1 className=" hidden hidden-md">VOYAGE VIBES<br></br>TUNE IN TO THE ADVENTURES </h1>
                        <h1 className=" hidden-lg">VOYAGE VIBES<br></br>TUNE IN TO THE ADVENTURES </h1>
                        <div className="d-flex  theme">
                           <Link to="https://youtu.be/xlf8ifUhS70?si=xZgz47SxYaqMUbl2" target="_blank">
                              <img src="/images/app-home/sec3/play-icon.svg" className="me-2 play-icon" alt="" />
                              <span>WATCH OUR THEME SONG</span>
                           </Link>
                        </div>
                        <p>Since he was a kid, Santappan dreamed of exploring new places. He kickstarted his YouTube channel with a "Cycle Series", and his followers loved it! Their support kept him going, and now "Travelista by santos" has a family of 716K followers.
                           His engaging storytelling and genuine enthusiasm for discovery transformed his channel into a vibrant community of travel enthusiasts, inspiring others to embark on exploration and cultural immersion journeys.<br></br><br></br>
                           Getting here wasn't easy, but Santappan's dream doesn't stop—it keeps growing to touch more hearts. This theme song is for all you dreamers out there to spark that wanderlust in your souls! 
                        </p>
                     </div>
                     
                     
                  </div>
                  <div className="col-lg-6 d-flex p-0 justify-content-end position-relative thumb-img">
                     <Link to="https://youtu.be/xlf8ifUhS70?si=xZgz47SxYaqMUbl2" target="_blank" className="d-block position-relative thumbnail-container"> 
                        <img src="/images/app-home/sec3/web.webp" alt="" className="thumbnail hidden hidden-md" ></img>
                        <img src="/images/app-home/sec3/tab.webp" alt="" className="thumbnail hidden-lg hidden" ></img>
                        <img src="/images/app-home/sec3/mob.webp" alt="" className="thumbnail hidden-lg hidden-md" ></img>
                        <img src="/images/app-home/Frame.svg" alt="" className="frame-icon"></img>
                     </Link>
                  </div>
               </div>
              
            </CommonSection>  
         </div>
       

         {/* SECTION 4  JOURNEY THROUGH THE LENS */}
         <CommonSection className="home-sec-4">
               <HomeGallery secData={sec4Data} secSlideSettings={sec4SlideSettings}/>
         </CommonSection>


         {/* SECTION 5 BLOG */}
         <div className="home-sec-5" style={{
               backgroundImage: 'url(/images/Blog-bg/home-blog-bg.png)',
               marginBottom: '1rem',
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               WebkitBackgroundSize: "cover",
               MozBackgroundSize: "cover",
               OBackgroundSize: "cover",
               paddingTop:"6rem"
            
            }}>

            <div className="slide-container">
               <div className="row sec-title mt-5 mb-5 hidden-lg hidden-md" >
                  <div className="col-12">
                     <h2 className="yellow-line mt-5" >EXPLORE<br/>OUR TRAVEL BLOGS</h2>
                  </div>
               </div>
               <BlogSlider secData={blogData} secTitle={(
                     <> EXPLORE <br className="web-view-break"/> OUR TRAVEL BLOGS </>
                  )} 
                   secSettings={sec5SlideSettings}></BlogSlider>
            </div>

           
           
         </div>

         {/* SCROLL TOP */}
         {/* <ScrollToTop/> */}
      </>
   );
};

export default Home
