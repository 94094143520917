import React, { useState } from 'react'
import "./base.css";
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import CommonSection from '../common-componets/CommonSection';
import ScrollToTop from '../common-componets/ScrollToTop';
import SecSlider from '../common-componets/SecSlider';
import DestinationAfrica from '../common-componets/Destinations/Africa';

const Africa = () => {

   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }
   const scrollTop = () =>{
      window.scrollTo(0,0);
   }

   const sec3Data = [
      {
         image_url: "/images/destination/2.png",
          title: "ZIMBABWE",
          description: "Gold Mining Plant",
          link:"https://youtu.be/D2iW6NF9CB0?si=E_8O-0HcB5iWtrjy"
      },
      {
         image_url: "/images/destination/3.png",
         title: "ZAMBIA",
         description: "Heartbreaking Realities Of Life",
         link:"https://youtu.be/nby4DbZ_UkA?si=nb5cxWH3lCsxuIRT"
      }
    ]
    const sec3SlideSettings = {
      dots: false,
      infinite: true,
      autoplay: false,
      // autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 3
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:2
            }
         },
         {
           breakpoint: 576,
           settings: {
              slidesToShow:1
           }
        }
      ],
      arrows:false
   };


  const sec5Data = [
    {
       image_url: "/images/destination/4.png",
       title: "ZAMBIA",
       description: "World’s Largest Emarald Mine",
       link:"https://youtu.be/QPSIlRF3o2c?si=6a62ZRJ5PRTDPmHm"
    },
    {
      image_url: "/images/destination/5.png",
       title: "KENYA",
       description: "Lion Hunting",
       link:"https://youtu.be/znYP7VKyKEA?si=FQOMosVmJsrlixVA"
    },
    {
      image_url: "/images/destination/6.png",
      title: "JOHANNESBURG",
      description: "Dangerous Place in Africa",
      link:"https://youtu.be/blhpuPKB8GM?si=AX7UcFAeSw7eE4eK"
   },
      {
      image_url: "/images/destination/7.png",
      title: "ZIMBABWE",
      description: "Esibomvu Tribal Village",
      link:"https://youtu.be/l_9OLgITJk4?si=-eQDznLcrsDyBjfa"
   },
   {
      image_url: "/images/destination/8.png",
      title: "ZAMBIA ",
      description: "Garden Route",
      link:"https://youtu.be/YA8xWEBjCaw?si=Ux3110hy0F7dtDvs "
   }

  ]

  // Custom previous and next arrows
   const PrevArrow = (props) => {
      const { className, onClick } = props;
      return (
      <div className={`custom-prev-arrow ${className}`} onClick={onClick}>
         Previous
      </div>
      );
   };
   
   const NextArrow = (props) => {
      const { className, onClick } = props;
      return (
      <div className={`custom-next-arrow ${className}`} onClick={onClick}>
         Next
      </div>
      );
   };
   
   const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow: 1
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ]
 };

  return (
    <>
       
       <DestinationAfrica/>

      {/* SCROLL TOP */}
      {/* <ScrollToTop/> */}

    </>
  )
}

export default Africa
