import React from 'react'
import CommonSection from '../common-componets/CommonSection';
import { useState, useEffect } from 'react';
import ScrollToTop from '../common-componets/ScrollToTop';
import SecSlider from '../common-componets/AdMediaSlider';
import './Admedia.css'

function Admedia() {
   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }

  const sec5Data = [
    {
       image_url: "/images/admedia/sec-4/1.png",
       image_url_md: "/images/admedia/sec-4/1-md.png",
       image_url_sm: "/images/admedia/sec-4/1-sm.png",
       title: "CORPORATE VIDEOS ",
    },
    {
      image_url: "/images/admedia/sec-4/2.png",
      image_url_md: "/images/admedia/sec-4/2-md.png",
      image_url_sm: "/images/admedia/sec-4/2-sm.png",
       title: "PROMOTIONAL VIDEOS ",
    },
    {
      image_url: "/images/admedia/sec-4/3.png",
      image_url_md: "/images/admedia/sec-4/3-md.png",
      image_url_sm: "/images/admedia/sec-4/3-sm.png",
      title: "MUSIC VIDEOS ",
    }
  ]
  const scrollTop = () =>{
   window.scrollTo(0,0);
}

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow:1,
             autoplay: false,
             
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ]
 };
 const sec5SlideSettings2 = {
   dots: false,
   infinite: false,
   autoplay: false,
   autoplaySpeed: 2000,
   speed: 500,
   slidesToShow: 3,
   slidesToScroll: 1,
   // variableWidth: true,
   responsive: [
      {
         breakpoint: 992,
         settings: {
            slidesToShow: 1
         }
      },
      {
         breakpoint: 768,
         settings: {
            slidesToShow:1,
            autoplay: true,
            
         }
      },
      {
        breakpoint: 576,
        settings: {
           slidesToShow:1
        }
     }
   ]
};

      //  BANNER EFFECT
      const [scrollScale, setScrollScale] = useState(1);

      useEffect(() => {
      const handleScroll = () => {
         const scrollPosition = window.scrollY;
         const maxZoomScroll = 400;

         // Calculate scale based on scroll position
         const scale = 1 + (scrollPosition / maxZoomScroll) * 0.08;
         setScrollScale(scale);
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
      }, []);
      // useEffect(() => {
      //    const banner = document.querySelector('.ad-media-banner-container ');
      //    banner.style.backgroundSize = ` ${100 * scrollScale}%`;
      //    const initialHeight = banner.offsetHeight;
      //    const scaledHeight = initialHeight * scrollScale;
      //    const translateY = scaledHeight - initialHeight;

      //    banner.style.backgroundPosition = `center bottom -${translateY}px`; 
      // }, [scrollScale]);

  return (
    <>
         <div class="parallax-container">
            <div class="container-fluid ad-media-banner-container">
               <div class="banner container position-relative d-flex justify-content-end">
                  <img src="/images/admedia/parallax.png" class="parallax-img" />
                  <div class="container text-overlay">
                     <div className=''>
                     <h1 className='hidden'>Advertising for<br/>lasting <span>IMPACT</span></h1>
                     <h1 className='hidden-md hidden-lg'>Advertising for lasting <span>IMPACT</span></h1>
                        <p >Transform your brand's story into captivating visuals with our expertly crafted video ads. Let your message shine through powerful and creative media solutions! </p>
                     </div>
                        

                  </div>
               </div>
            </div>
         </div>


         <div class='admedia-section-2'>
            <img className='sec2-img' src='/images/admedia/sec-2.png' />
            <div className='sec-title'>
               <h1 className="hidden-md hidden">Creative<br></br>Advertising<br></br>Beyond<br/>Boundaries. </h1>
               <h1 className="hidden-lg">Creative Advertising<br></br>Beyond Boundaries. </h1>
               <div className='blackline'></div>
            </div>
         </div>
         <div class='admedia-section-3'>
            <div className='sec-content'>
               <p>Welcome to a world where your brand's narrative meets captivating visuals! We specialise in crafting visually stunning and engaging video content tailored to showcase your unique brand identity.</p>

               <p>With an innate understanding of visual aesthetics and a passion for travel, our video ads aren't just advertisements—they're captivating narratives waiting to unfold. Every frame is meticulously crafted to evoke emotions, inspire curiosity, and drive engagement. </p>
               <p>Let your audience embark on a visual journey that showcases your offerings and creates an emotional connection. It's time to let your story come alive through the lens of travel and exploration, leaving a lasting impact on every viewer's mind and heart. 
               </p>
            </div>
            
         </div>
         
         <div class='admedia-section-4'>
            {sec5Data.map((item, index) => (
                  <div className="image-container" key={index}>
                     <img className='hidden hidden-md' src={item.image_url} alt={item.title} />
                     <img className='hidden hidden-lg' src={item.image_url_md} alt={item.title} />
                     <img className='hidden-lg hidden-md' src={item.image_url_sm} alt={item.title} />
                     <div className="overlay"></div>
                     <div className="title">{item.title}</div>
                     <div className="line"></div>
                  </div>
               ))}
         </div>



      {/* SCROLL TOP */}
      {/* <ScrollToTop/> */}


    </>
  )
}

export default Admedia
