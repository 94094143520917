import React, {useState, useRef}  from "react";
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import PaginationButton from "../PaginationButton";
import { BannerData } from "../../constants/HomeBanner";
import './Banner.css'

const Banner = () => {
   // BANNER PAGINATION
   const sliderRef = useRef(null);
   const [currentSlide, setCurrentSlide] = useState(0);
   const totalSlides = BannerData.length;

   const handleSlideChange = (index) => {
      setCurrentSlide(index);
      sliderRef.current.slickGoTo(index);
    };
    
    

   const sec1SlideSettings = {
      initialSlide: currentSlide,
      afterChange: handleSlideChange,
      dots: false,
      infinite: true,
      autoplay: true,   
      autoplaySpeed: 3000,
      fade:true,
      cssEase:'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 1
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:1
            }
         },
         {
            breakpoint: 576,
            settings: {
               slidesToShow:1
            }
         }
      ],
      arrows:false
   };
   
  return (
    <>
        <Slider {...sec1SlideSettings} ref={sliderRef} className="">
            {BannerData.map((item, index) => {
               return (
                  <div key={index}>
                     <div className="home-banner" style={{
                        backgroundImage: `url(${
                           window.innerWidth <= 576
                             ? item.image_url_mobile
                             : window.innerWidth <= 992
                               ? item.image_url_tab
                               : item.image_url
                         })`
                        
                     }} >
                     <div className=" banner container" >
                           <div className="banner-content" >
                              <h1 className={index === 0 ? 'first-slide' : ''}>
                                    {item.title} 
                                    {item.destination && <><br className="hidden-lg" /> {item.destination}</>}
                                    {index !== 0 && <br/>}
                                    <span>{index === 0 ? item.title1 : item.title2}</span>
                                    {/* {index !== 0 && <br></br>} */}
                                    {index === 0 ? (
                                       <svg width="370" height="65" viewBox="0 0 367 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M125.023 56.6557L94.4043 0L115.431 56.6557H125.023Z" fill="#FFF200"/>
                                       <path d="M10.8747 28.0988H0V22.2236H22.3369V28.0988H17.343V56.5085H10.8803V28.0988H10.8747Z" fill="white"/>
                                       <path d="M138.783 56.6554H132.072L117.819 22.2236H125.067L135.4 48.916L145.783 22.2236H153.031L138.778 56.6554H138.783Z" fill="white"/>
                                       <path d="M207.759 22.2188V50.5268H225.39V56.5037H201.291V22.2188H207.754H207.759Z" fill="white"/>
                                       <path d="M236.854 56.5085V22.2236H243.271V56.5085H236.854Z" fill="white"/>
                                       <path d="M278.346 30.4997C277.171 28.5394 273.94 26.6809 270.217 26.6809C265.415 26.6809 263.116 28.692 263.116 31.2341C263.116 34.2225 266.641 35.053 270.759 35.5444C277.911 36.4257 284.572 38.2843 284.572 46.4643C284.572 54.1077 277.81 57.3842 270.172 57.3842C263.167 57.3842 257.777 55.2318 255.235 48.9613L260.625 46.1706C262.144 49.9442 266.11 51.6051 270.273 51.6051C274.437 51.6051 278.16 50.1871 278.16 46.4643C278.16 43.233 274.782 41.9111 270.223 41.4196C263.218 40.5892 256.755 38.7249 256.755 31.0364C256.755 23.9862 263.709 21.0938 270.025 21.043C275.363 21.043 280.9 22.5626 283.493 27.8502L278.352 30.4941L278.346 30.4997Z" fill="white"/>
                                       <path d="M305.288 28.0988H294.413V22.2236H322.625V28.0988H311.75V56.5085H305.288V28.0988Z" fill="white"/>
                                       <path d="M163.77 22.2188V28.5345H170.187V28.4893H189.434V22.2188H163.77Z" fill="white"/>
                                       <path d="M170.188 42.4321V42.4039H179.515V36.3818H163.771V42.4378H170.188V42.4321Z" fill="white"/>
                                       <path d="M173.018 50.1367V50.2667H163.776V56.5034H189.441V50.1367H173.018Z" fill="white"/>
                                       <path d="M28.213 16.293H22.3379V22.1681H28.213V16.293Z" fill="white"/>
                                       <path d="M109.34 56.6562L95.0874 22.2188H88.3818L74.1289 56.6562H81.3768L91.76 29.9581L97.5448 44.9002H91.4041L89.5399 50.7754H99.8158L102.092 56.6562H109.34Z" fill="white"/>
                                       <path d="M56.5886 44.357C63.1021 43.0351 65.2601 38.2841 65.2601 33.5783C65.2601 27.6524 61.0006 22.263 52.9166 22.2178C47.4821 22.2178 42.0419 22.167 36.6074 22.167V28.2399H52.9166C56.984 28.2399 58.7918 30.9854 58.7918 33.7252C58.7918 36.4651 57.0292 39.2106 52.9166 39.2106H43.0701V34.115H36.6074V56.4971H43.0701V45.0349H49.2898L59.2833 56.4971H66.9718V56.0565L56.5886 44.3514V44.357Z" fill="white"/>
                                       <path d="M366.068 56.6562L351.815 22.2188H345.104L330.851 56.6562H338.099L348.482 29.9581L354.266 44.9002H348.131L346.262 50.7754H356.543L358.82 56.6562H366.068Z" fill="white"/>
                                       </svg>
                                       
                                    ) : (
                                       <span>{item.title3}</span>
                                    )}
                              </h1>
                              <p>{item.description}<Link to="https://www.youtube.com/@Travelistabysantos" target="_blank"><span className="">{item.bold}</span></Link>{item.description1}</p>
                           </div>

                           {/* PAGINATION BUTTONS */}
                           <div className="pagination-btns hidden">
                              {[currentSlide - 1, currentSlide, currentSlide + 1].map((index) => (
                                 <PaginationButton
                                    key={index}
                                    index={(index + totalSlides) % totalSlides}
                                    currentIndex={currentSlide}
                                    totalSlides={totalSlides}
                                    onClick={handleSlideChange}
                                 />
                                 ))}
                           </div>

                           <div className=" sm-pagination d-md-none">
                              <div className="row sm-pagination-buttons">
                              {[currentSlide - 1, currentSlide, currentSlide + 1].map((index) => (
                                    <PaginationButton
                                       key={index}
                                       index={(index + totalSlides) % totalSlides}
                                       currentIndex={currentSlide}
                                       totalSlides={totalSlides}
                                       onClick={handleSlideChange}
                                    />
                                    ))}
                              </div>
                           </div>
                           

                        
                     </div>
                  </div>
               </div>
               )
               })}
         </Slider>
    </>
  )
}

export default Banner