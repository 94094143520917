export const sec2Data = [
    {
       image_url: "/images/app-home/sec2/1.png",
       title: "Eiffel Tower",
       description: "",
       link:"https://youtu.be/RksdZWD4Udg?si=1-JAnRBmLrJjvBSb"
    },
    {
       image_url: "/images/app-home/sec2/2.png",
       title: "MACHU PICCHU ",
       description: "",
       link:"https://youtu.be/tlyDB9C91rQ?si=g7bXM2jGrKxmVHy8"
    },
    {
       image_url: "/images/app-home/sec2/3.png",
       title: "Colosseum",
       description: "",
       link:"https://youtu.be/CvtQjenmOuQ?si=YM3xXylvUlAOjnJO"
    },
    {
       image_url: "/images/app-home/sec2/4.png",
       title: "ANTARCTICA ",
       description: "",
       link:"https://youtu.be/jP5wOILdh7k?si=lnAZzSAO9iDfPHJK"
    },
    {
       image_url: "/images/app-home/sec2/5.png",
       title: "Egypt",
       description: "",
       link:"https://youtu.be/QIlQlXp9_Q0?si=uahhjqoIhHyFeZvt"
    },
    {
        image_url: "/images/app-home/sec2/6.png",
        title: "Masai Mara",
        description: "",
        link:"https://youtu.be/6HSR0lqtA-c?si=h2aovxHvSEWbgGJs"
     },
     {
        image_url: "/images/app-home/sec2/7.png",
        title: "Victoria Waterfalls",
        description: "",
        link:"https://youtu.be/k-Q-gLoQql0?si=rSMzNOod-lgaI9nA"
     },
     {
        image_url: "/images/app-home/sec2/8.png",
        title: "London",
        description: "",
        link:"https://youtu.be/Thc-uC44WUk?si=50MmDO1W4t04TFEM"
     },
     
 ]