import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './loader.js';
import Layout from "./common-componets/Layout";


function App() {
  return (
    <div className="App">
      
      <Router>
          <Routes >

            <Route path="/*" element={<Layout/>}/>

          </Routes >
      </Router>

    </div>
  );
}

export default App;
