import React, { useState } from 'react'
import "./base.css";
import Slider from "react-slick";
import CommonSection from '../common-componets/CommonSection';
import {  Link } from "react-router-dom";
import ScrollToTop from '../common-componets/ScrollToTop';
import SecSlider from '../common-componets/SecSlider';

import Destination from '../common-componets/Destinations/Destination';

const Asia = () => {
   

   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }
   

   const sec3Data = [
      {
         image_url: "/images/asia/sec-3/1.png",
          title: "NEPAL ",
          description: "Climbing Mount Everest",
          link:"https://youtu.be/_JILQ0fxUdA?si=vaZXQb0Q9_dF4G2O"
      },
      {
         image_url: "/images/asia/sec-3/2.png",
         title: "INDIA ",
         description: "India-Pak Border",
         link:"https://youtu.be/-sDOZmv0Zjg?si=IXX2Dw6X4J3kpO-W"
      }
    ]
    const sec3SlideSettings = {
      dots: false,
      infinite: true,
      autoplay: false,
      // autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows:true,
      
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 3
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:2
            }
         },
         {
           breakpoint: 576,
           settings: {
              slidesToShow:1
           }
        }
      ],
      arrows:false
   };

   // Custom previous and next arrows
   const PrevArrow = (props) => {
      const { className, onClick } = props;
      return (
        <div className={`custom-prev-arrow ${className}`} onClick={onClick}>
          Previous
        </div>
      );
    };
    
    const NextArrow = (props) => {
      const { className, onClick } = props;
      return (
        <div className={`custom-next-arrow ${className}`} onClick={onClick}>
          Next
        </div>
      );
    };
    
  const sec5Data = [
    {
       image_url: "/images/asia/sec-5/1.png",
       title: "INDIA ",
       description: "Indo-Tibet Border ",
       link:"https://youtu.be/Jkpeu1MdWwg?si=PEexK9sZKqpC8c8t"
    },
    {
      image_url: "/images/asia/sec-5/2.png",
       title: "NEPAL ",
       description: "Nepal Expedition ",
       link:"https://youtu.be/3t-uj8OvMhE?si=dA3CnxzbAqdvdTEw"
    },
    {
      image_url: "/images/asia/sec-5/3.png",
      title: "MALAYASIA ",
      description: "Malaysia-Thailand Border ",
      link:"https://youtu.be/cOPkLDla2V0?si=beGP8bR8IDMo2mx1"
   },
      {
      image_url: "/images/asia/sec-5/4.png",
      title: "NEPAL ",
      description: "Dangerous Road in Nepal ",
      link:"https://youtu.be/BovqLuZ9bHg?si=o4D6XdpQ5QpRe8Bd "
   },
   {
      image_url: "/images/asia/sec-5/5.png",
      title: "INDIA  ",
      description: "Baghori Village ",
      link:"https://youtu.be/iPW3s5F-1to?si=Gx0jqtJnw0ffmDwh"
   }

  ]

  const scrollTop = () =>{
   window.scrollTo(0,0);
}
   const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow:1
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ],
    arrows:true
 };

  return (
    <>
       
       <Destination/>

          {/* SCROLL TOP */}
          {/* <ScrollToTop/> */}


    </>
  )
}

export default Asia
