import React, { useState } from 'react'
import "./base.css";
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import CommonSection from '../common-componets/CommonSection';
import ScrollToTop from '../common-componets/ScrollToTop';
import SecSlider from '../common-componets/SecSlider';
import DestinationMEast from '../common-componets/Destinations/MiddleEast';

const MiddleEast = () => {

   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }
   const sec3Data = [
      {
         image_url: "/images/middleEast/12.png",
          title: "DUBAI",
          description: "Heading to Dubai",
          link:"https://youtu.be/sBM54uNnSoU?si=wdI_Bbv89O9r0GyJ"
      },
      {
         image_url: "/images/middleEast/13.png",
         title: "DUBAI",
         description: "Vanlife in Dubai ",
         link:"https://youtu.be/iuycvR_gCCY?si=M2DhsaQ87IcL3Zl2"
      }
    ]
    const sec3SlideSettings = {
      dots: false,
      infinite: true,
      autoplay: false,
      // autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 3
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:2
            }
         },
         {
           breakpoint: 576,
           settings: {
              slidesToShow:1
           }
        }
      ],
      arrows:false
   };


  const sec5Data = [
    {
       image_url: "/images/middleEast/8.png",
       title: "DUBAI ",
       description: "Hospitality of Arabs",
       link:"https://youtu.be/yoCZDgbj2Rk?si=b-a38hY0L2Y07nZ5 "
    },
    {
      image_url: "/images/middleEast/9.png",
       title: "DUBAI ",
       description: "A Village Lost in Time ",
       link:"https://youtu.be/yUWYbukcZsQ?si=P8jBSXC7hWCGpyZn "
    },
    {
      image_url: "/images/middleEast/4.png",
      title: "DUBAI ",
      description: "Camel Milking ",
      link:"https://youtu.be/f3vHCnq-b3k?si=HiDkjBTh0PsVbSRT "
   },
      {
      image_url: "/images/middleEast/10.png",
      title: "DUBAI ",
      description: "Emirati Majboos Biryani ",
      link:"https://youtu.be/PfTzLqfkmnU?si=7sRoq4XYInAczPFn "
   },
   {
      image_url: "/images/middleEast/11.png",
      title: "DUBAI  ",
      description: "Hot Air Balloon ",
      link:"https://youtu.be/PDpnLxOclcE?si=cDtx3b7H4uLhngaF "
   }

  ]

  const scrollTop = () =>{
      window.scrollTo(0,0);
   }

   // Custom previous and next arrows
   const PrevArrow = (props) => {
      const { className, onClick } = props;
      return (
        <div className={`custom-prev-arrow ${className}`} onClick={onClick}>
          Previous
        </div>
      );
    };
    
    const NextArrow = (props) => {
      const { className, onClick } = props;
      return (
        <div className={`custom-next-arrow ${className}`} onClick={onClick}>
          Next
        </div>
      );
    };
   const sec5SlideSettings = {
   variableWidth: true,
   prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
   dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow:1
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ]
 };

  return (
    <>
       
       <DestinationMEast/>

         {/* SCROLL TOP */}
         {/* <ScrollToTop/> */}

    </>
  )
}

export default MiddleEast
