import React, { useState } from "react";
import './NavSubList.css';
import { Link } from "react-router-dom";
import { navArray } from "../constants/navArray";





const NavSubList = ({ navIndex, navLeft ,toggleSubNav,activeMainPageURL  }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(null);
    var columnCnt
    if ((window.innerWidth >= 992)) {
        columnCnt = 4  // to get columnCount of ul to arrange in columns with max 4 elements
    }
    else {
        columnCnt = 1
    }

    var LEFT;

    if(navIndex===3){
        LEFT="225px"
    }
    else if(navIndex===1){
        LEFT="35px"
    }
    else{
        LEFT="0px"
    }

    const [dropdown, setDropdown]= useState(false)
  
    const[toggleSubSubList, setToggleSubSubList]= useState(false)
     
    const toggle= () =>{
        setToggleSubSubList(!toggleSubSubList);
    }
    
    let toggleCheck = toggleSubSubList ? ' active' : '' ;
    
    return (
        <div className="subMenu">
            <div className="">
                    {navArray[navIndex].subMenu.map((item, i) => {
                        const isActive = item.path === activeMainPageURL;
                        return (
                            <li key={i} className={isActive ? "active" : ""} >
                                <Link className="" onClick={() => { setActiveItemIndex(i); toggle(); toggleSubNav(); }} to={item.path}>{item.label}
                                </Link>
                            </li>
                        )
                        
                            
                    })}

            </div>

        </div>
    )
}


export default NavSubList