import React from 'react'
import "./base.css";
import CommonSection from '../common-componets/CommonSection';
import {  Link } from "react-router-dom";

const ShopSub2 = () => {
    const sec4Data = [
        {
           image_url: "/images/shop_sub2/sec-4/outdoor.png",
        },
        {
            image_url: "/images/shop_sub2/sec-4/Outdoor sofa set_2 1.png",
         },
         {
            image_url: "/images/shop_sub2/sec-4/Stuart sofa 1.png",
         },
         {
            image_url: "/images/shop_sub2/sec-4/sofa.png",
         }
        ]

        const sec3Data = [
            {
               image_url: "/images/shop/sec-3/7.png",
               title: "Pobel bekurade",
               description: "Belingar närvaropeng",
               image_url2: "/images/star.svg",
               price: "￡10.00"
            },
            {
                image_url: "/images/shop/sec-3/3.png",
                title: "Pobel bekurade",
                description: "Belingar närvaropeng",
                image_url2: "/images/star.svg",
                price: "￡10.00"
             },
             {
                image_url: "/images/shop/sec-3/1.png",
                title: "Pobel bekurade",
                description: "Belingar närvaropeng",
                image_url2: "/images/star.svg",
                price: "￡10.00"
             },
             {
                image_url: "/images/shop/sec-3/2.png",
                title: "Pobel bekurade",
                description: "Belingar närvaropeng",
                image_url2: "/images/star.svg",
                price: "￡10.00"
             }
            ]
            const sec3mdData = [
                {
                   image_url: "/images/shop/sec-3/7.png",
                   title: "Pobel bekurade",
                   description: "Belingar närvaropeng",
                   image_url2: "/images/star.svg",
                   price: "￡10.00"
                },
                {
                    image_url: "/images/shop/sec-3/3.png",
                    title: "Pobel bekurade",
                    description: "Belingar närvaropeng",
                    image_url2: "/images/star.svg",
                    price: "￡10.00"
                 }
                ]
                const sec33mdData = [
                    {
                       image_url: "/images/shop/sec-3/3.png",
                       title: "Pobel bekurade",
                       description: "Belingar närvaropeng",
                       image_url2: "/images/star.svg",
                       price: "￡10.00"
                    },
                    {
                        image_url: "/images/shop/sec-3/4.png",
                        title: "Pobel bekurade",
                        description: "Belingar närvaropeng",
                        image_url2: "/images/star.svg",
                        price: "￡7.000.000"
                     }
                    ]
         
return (
    <>


<CommonSection className={' shopSub-sec-2 hidden'}>
      <div className='container shopSub2-sec-2 '>
            <div className='row d-flex pt-lg-4 pb-lg-2 '>
                <div className='col-1 me-2 hidden  hidden-md'>
                    <p>Home</p>
                </div>
                <div className='col-1 hidden  hidden-md'>
                    <img src="/images/arrow.svg" alt=""></img>
                </div>
                <div className='col-1 me-2 hidden hidden-md'>
                    <p>Shop</p>
                </div>
                <div className='col-1 hidden hidden-md'>
                    <img src="/images/arrow.svg" alt=""></img>
                </div>
                <div className='col-1 hidden hidden-md'>
                    <img src="/images/line.svg" alt=""></img>
                </div>
                <div className='col-2 hidden hidden-md'>
                    <h5>Dyssa derede. </h5>
                </div>
            
            </div>
      </div>
    </CommonSection>



    <CommonSection className={"shopSub2-sec-4 pt-lg-5 pb-5"}>
        <div className='container pt-lg-5 flex-column d-flex  align-items-center justify-content-center '>
            <div className='row'>
                <div className='col-lg-1 hidden hidden-md'>
                    {sec4Data.map((item, index) => {
                        return (
                        <div className="card" style={{ height: "70px", width:"53px", marginBottom:"40px" }}>
                            <div className="box">
                                <img src={item.image_url} alt=''/>
                            </div>   
                        </div>
                        )
                    })} 
                </div>

                <div className='col-lg-5 col-md-12 sofa'>
                    <img src="/images/shop_sub2/sec-4/sofa-big.png" alt="" className='mt-5'></img>
                </div>

                <div className='col-lg-6 col-md-8 hidden hidden-md'>
                    <h2>Dyssa derede.</h2>
                    <h4>￡10.00</h4>

                    <div className='row hidden'>
                        <div className='col-2'>
                            <img src="/images/shop_sub2/sec-4/star.svg" alt=""></img>
                        </div>
                    </div>
                 
                    <p className='mt-3 hidden'>Setting the bar as one of the loudest speakers in its class, the Kilburn is a compact, 
                    stout-hearted hero with a well-balanced audio which boasts a clear midrange and extended highs 
                    for a sound.</p>

                    <div className="row w-100 hidden">
                        <div className='count col-4 mt-5'>
                            
                                <button>-</button>
                                <input value={(0)}/>
                                <button>+</button>
                            
                        </div>
                        <div className="col-6 hidden">
                        <Link to="/checkout"><button className='btn btn-primary mt-5'>Add to cart</button></Link>

                        </div>
                    </div>
                </div>

                <div className='container hidden-lg hidden-md sh-sec-5'>
                    <div className='row mt-5'>
                        <div className=' col-7'>
                            <h2>Dyssa derede.</h2>
                        </div>
                        <div className='col-5'>
                            <h4>￡10.00</h4>
                            <div className=' d-flex justify-content-end'>
                                <img src="/images/shop_sub2/sec-4/star.svg" alt=""></img>
                            </div>
                            <h6>5 customer review</h6>
                        </div>
                    </div>
                    
                    <p className='mt-3'>Setting the bar as one of the loudest speakers in its class, the Kilburn is a compact, 
                    stout-hearted hero with a well-balanced audio which boasts a clear midrange and extended highs 
                    for a sound.</p>

                    <div className="row">
                        <div className="col-12 ">
                            <div className='count col-4 mt-4'>
                                
                                <button>-</button>
                                <input value={(0)}/>
                                <button>+</button>
                            
                            </div>
                        </div>
                        
                            <div className='col-12 mt-4'>
                            <Link to="/checkout"><button className='btn btn-primary mt-1'>Add to cart</button></Link>
                            
                        </div>
                    </div>
                </div>
               
                
            </div>
        </div>
    </CommonSection>






 
    <CommonSection className={"shop pb-5 pt-5 hidden-md"}>
      <div className='container flex-column d-flex align-items-center justify-content-center shop-sec-3 pt-3 mb-5'>
            <div className='row'>
                <div className='col-12 mb-5'>
                    <h2 >Featured Products</h2>
                </div>
            </div>
            <div className="d-lg-flex w-100 align-items-center justify-content-center">
                {sec3Data.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn mt-5'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })} 
            </div> 
            <div className='row  '>
                <div className='col-12'>
                    <Link to="/ShopSub"><button className='btn btn-primary mx-auto'>Shop more</button></Link>
                </div>
            </div>
    </div>
    </CommonSection>


    {/* medium screen */}

    <CommonSection className={"shop pt-4 pb-5 hidden-lg hidden"}>
      <div className='container flex-column d-flex shop-sec-3 mt-5 mb-5'>
      <div className='row'>
                <div className='col-12 mb-3'>
                    <h2 >Featured Products</h2>
                </div>
            </div>
            <div className="d-flex w-100 mb-5 align-items-center justify-content-center">
                {sec3mdData.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn mt-5'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })} 
            </div> 
                <div className="d-flex w-100 mt-5 mb-5 align-items-center justify-content-center ">
                {sec33mdData.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn mt-5'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className="d-flex w-100 mt-5 mb-5 hidden align-items-center justify-content-center">
                {sec3mdData.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn mt-5'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className="d-flex hidden w-100 mt-5 mb-5 align-items-center justify-content-center">
                {sec33mdData.map((item, index) => {
                    return (
                        <div className="card">
                        <div className="box">
                            <div className='tag1'>
                                        <h2>50%<br></br>OFF</h2>
                            </div>
                           
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                <Link to="/cart"><button className='btn mt-5'>Add to cart</button></Link>
                                <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                        </div>
                </div>
                    )
                })}  
            </div>
            <div className='row flex-column d-flex align-items-center justify-content-center mt-4'>
                <div className='col-12 mt-5'>
                    <Link to="/ShopSub"><button className='btn btn-primary mx-auto'>Shop more</button></Link>
                </div>
            </div>
        </div>
    </CommonSection>





    <CommonSection className="container home-sec-6">
            <div className="row align-items-center flex-column">
               <div className="col-12 d-flex justify-content-lg-center  justify-content-md-center  mt-5 mb-3">
                     <h1 >Travel<span>.</span> Capture<span>.</span> Share<span>.</span></h1>
               </div>
               <div className="col-12 d-flex  justify-content-lg-center  justify-content-md-center justify-content-sm-start  mb-2">
                  <p>The world is our playground, and every adventure with Travelista will be an unforgettable chapter in your travel story. </p>
               </div>
               <div className="col-12 d-flex  justify-content-lg-center  justify-content-md-center justify-content-sm-start  mt-2 mb-lg-5">
                  <button className="btn btn-primary me-2 mb-3 travel-btn">Plan your Travel</button>
                  <button className="btn btn-primary button">👋  Contact</button>
               </div>
            </div>
         </CommonSection>

    </>
  )
}

export default ShopSub2
