// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.mast__title span {
    opacity: 0;
    animation: letter-glow 0.1s ease-in-out forwards;
}

@keyframes letter-glow {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 1px rgba(255,255,255,0.1);
  }
  66% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgb(255, 255, 255);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba(255,255,255,0.0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/common-componets/Destinations/Text.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,gDAAgD;AACpD;;AAEA;EACE;IACE,UAAU;IACV,8CAA8C;EAChD;EACA;IACE,UAAU;IACV,4CAA4C;EAC9C;EACA;IACE,UAAU;IACV,+CAA+C;EACjD;AACF","sourcesContent":["\r\n.mast__title span {\r\n    opacity: 0;\r\n    animation: letter-glow 0.1s ease-in-out forwards;\r\n}\r\n\r\n@keyframes letter-glow {\r\n  0% {\r\n    opacity: 0;\r\n    text-shadow: 0px 0px 1px rgba(255,255,255,0.1);\r\n  }\r\n  66% {\r\n    opacity: 1;\r\n    text-shadow: 0px 0px 20px rgb(255, 255, 255);\r\n  }\r\n  100% {\r\n    opacity: 1;\r\n    text-shadow: 0px 0px 20px rgba(255,255,255,0.0);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
