import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import ScrollToTop from '../ScrollToTop';
import './Destination.css'
import BlogSlider from '../Home/HomeBlogSlider';
import TextTransition from './TextTransition';


const DestinationMEast = () => {
   

    const[activeIndex,setActiveIndex]=useState(null);

    const imageHover = (index) => {
 
       setActiveIndex(index);
    }
    const sec3Data = [
       {
          image_url: "/images/middleEast/12.png",
           title: "DUBAI",
           description: "Heading to Dubai",
           link:"https://youtu.be/sBM54uNnSoU?si=wdI_Bbv89O9r0GyJ"
       },
       {
          image_url: "/images/middleEast/13.png",
          title: "DUBAI",
          description: "Vanlife in Dubai ",
          link:"https://youtu.be/iuycvR_gCCY?si=M2DhsaQ87IcL3Zl2"
       }
     ]
     const sec3SlideSettings = {
       dots: false,
       infinite: true,
       autoplay: false,
       // autoplaySpeed: 2000,
       speed: 500,
       slidesToShow: 3,
       slidesToScroll: 1,
       responsive: [
          {
             breakpoint: 992,
             settings: {
                slidesToShow: 3
             }
          },
          {
             breakpoint: 768,
             settings: {
                slidesToShow:2
             }
          },
          {
            breakpoint: 576,
            settings: {
               slidesToShow:1
            }
         }
       ],
       arrows:false
    };
 
 
   const sec5Data = [
     {
        image_url: "/images/middleEast/sec-5/1.png",
        title: "DUBAI ",
        description: "Hospitality of Arabs",
        link:"https://youtu.be/yoCZDgbj2Rk?si=b-a38hY0L2Y07nZ5 "
     },
     {
       image_url: "/images/middleEast/sec-5/2.png",
        title: "DUBAI ",
        description: "A Village Lost in Time ",
        link:"https://youtu.be/yUWYbukcZsQ?si=P8jBSXC7hWCGpyZn "
     },
     {
       image_url: "/images/middleEast/sec-5/3.png",
       title: "DUBAI ",
       description: "Camel Milking ",
       link:"https://youtu.be/f3vHCnq-b3k?si=HiDkjBTh0PsVbSRT "
    },
       {
       image_url: "/images/middleEast/sec-5/4.png",
       title: "DUBAI ",
       description: "Emirati Majboos Biryani ",
       link:"https://youtu.be/PfTzLqfkmnU?si=7sRoq4XYInAczPFn "
    },
    {
       image_url: "/images/middleEast/sec-5/5.png",
       title: "DUBAI  ",
       description: "Hot Air Balloon ",
       link:"https://youtu.be/PDpnLxOclcE?si=cDtx3b7H4uLhngaF "
    }
 
   ]
 
   const scrollTop = () =>{
       window.scrollTo(0,0);
    }
 
    // Custom previous and next arrows
    const PrevArrow = (props) => {
       const { className, onClick } = props;
       return (
         <div className={`custom-prev-arrow ${className}`} onClick={onClick}>
           Previous
         </div>
       );
     };
     
     const NextArrow = (props) => {
       const { className, onClick } = props;
       return (
         <div className={`custom-next-arrow ${className}`} onClick={onClick}>
           Next
         </div>
       );
     };
    const sec5SlideSettings = {
    variableWidth: true,
    prevArrow: <PrevArrow />,
     nextArrow: <NextArrow />,
    dots: false,
     infinite: true,
     autoplay: false,
     autoplaySpeed: 2000,
     speed: 500,
     slidesToShow: 3,
     slidesToScroll: 1,
     responsive: [
        {
           breakpoint: 992,
           settings: {
              slidesToShow: 1
           }
        },
        {
           breakpoint: 768,
           settings: {
              slidesToShow:1
           }
        },
        {
          breakpoint: 576,
          settings: {
             slidesToShow:1
          }
       }
     ]
  };
   //  BANNER EFFECT
   const [scrollOpacity, setScrollOpacity] = useState(1);
   const [scrollScale, setScrollScale] = useState(1);
 
   useEffect(() => {
     const handleScroll = () => {
       const scrollPosition = window.scrollY;
       const maxOpacityScroll = 400; 
       const maxZoomScroll = 800; 
 
       // Calculate opacity based on scroll position
       const opacity = 1 - (scrollPosition / maxOpacityScroll);
       setScrollOpacity(opacity < 0 ? 0 : opacity);
 
       // Calculate scale based on scroll position
       const scale = 1 + (scrollPosition / maxZoomScroll) * 0.2; 
       setScrollScale(scale);
     };
 
     window.addEventListener('scroll', handleScroll);
     return () => window.removeEventListener('scroll', handleScroll);
   }, []);
   
     useEffect(() => {
       const images = document.querySelectorAll('.dest-section-1 img');
       images.forEach(img => {
         img.style.opacity = scrollOpacity;
         img.style.transform = `scale(${scrollScale})`;
       });
     }, [scrollOpacity, scrollScale]);
     
 
 
  return (
    <>
       
       <div class="dest-section-1 img_container top_img">
         <img alt='' className='hidden hidden-md' src="/images/middleEast/banner/1.png" />
            <img alt='' className='hidden-lg hidden' src="/images/middleEast/banner/1-md.png" />
            <img alt='' className='hidden-lg hidden-md' src="/images/middleEast/banner/1-sm.png" />
          <div class="banner-content" >
            <h1 >TRAVEL IN<br/><span>Middle<br/>East</span></h1>
            <p className='text-white text-align-justify mt-4'>Explore the wonders of the Middle East with our special tours, which will show you its fascinating culture, history, and people's hospitality.</p>
            <Link to="/contact" onClick={scrollTop}><button className="btn btn-primary destination-contact"> 
                  Contact Us
                  
            </button></Link>
          </div>
      </div>

      
      
      <div class='dest-section-4'>
         <img alt='' className='sec2-img hidden hidden-md' src='/images/middleEast/sec-4/1.png' />
         <img alt='' className='sec2-img hidden-lg hidden' src='/images/middleEast/sec-4/1-md.png' />
         <img alt='' className='sec2-img hidden-lg hidden-md' src='/images/middleEast/sec-4/1-sm.png' />
         <div className='sec-title'>
            <div className='yellowline'></div>
            <h1 className="hidden-md hidden">EXPLORING THE<br/>ENCHANTING<br/>WONDERS OF THE<br/>MIDDLE EAST</h1>
            <h1 className="hidden-lg">EXPLORING THE ENCHANTING WONDERS OF THE MIDDLE EAST</h1>
            <p>
            Begin your journey in Dubai, a cityscape adorned with architectural marvels like the Burj Khalifa, the world's tallest building, and the stunning Palm Jumeirah, an iconic man-made island. 
            As you venture beyond the city's skyline, discover the serene beauty of the UAE's deserts. The mesmerising golden sands of the Arabian Desert offer opportunities for thrilling dune bashing, camel rides, and starlit desert camps that immerse you in Bedouin traditions. 
            The UAE seamlessly weaves together the luxury of modern cities and the raw beauty of its natural landscapes, offering travellers an extraordinary blend of experiences that redefine the boundaries of exploration. </p>           
         </div>
      </div>
      
      <div class="dest-section-2 main-section">
         <div className='sec-title'>
            <h1 className="">DISCOVER<br/>MIDDLE EASTERN<br></br>Journey Through<br/>My Vlogs </h1>
            <div className='blackline'></div>
         </div>
         
         <div className='sec-card'>
            <Link to="https://youtu.be/sBM54uNnSoU?si=wdI_Bbv89O9r0GyJ" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md ' src='/images/middleEast/sec-2/1.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/middleEast/sec-2/1-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/middleEast/sec-2/1-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">DUBAI</div>
                  <div className="description">Heading to Dubai</div>
                  <div className="line"></div>
               </div>
            </Link>
            <Link to="https://youtu.be/iuycvR_gCCY?si=M2DhsaQ87IcL3Zl2" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md' src='/images/middleEast/sec-2/2.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/middleEast/sec-2/2-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/middleEast/sec-2/2-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">DUBAI</div>
                  <div className="description">Vanlife in Dubai</div>
                  <div className="line"></div>
               </div>
            </Link>
         </div>
         
      </div>

      <div class='dest-section-3'>
         <div className='sec-content'>
            <TextTransition text="The Middle East's architectural beauty not only captivates the eye but also offers a glimpse into the cultural, religious, and artistic legacies that have shaped this region. ">

            </TextTransition>
         </div>
      </div>

      

      {/* CARD */}
      <div className="home-sec-5" style={{
               backgroundImage: 'url(/images/Blog-bg/middleast-blog-bg.png)',
               marginBottom: '1rem',
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               WebkitBackgroundSize: "cover",
               MozBackgroundSize: "cover",
               OBackgroundSize: "cover",
               paddingTop:"6rem"
            
            }}>

         <div className="slide-container">
            <div className="row sec-title mt-5 mb-5 hidden-lg hidden-md" >
               <div className="col-12">
                  <h2 className="yellow-line mt-5" >DISCOVER MORE<br/>MIDDLE EASTERN<br/>JOURNEYS</h2>
               </div>
            </div>
            <BlogSlider secData={sec5Data} 
               secTitle={( <> DISCOVER MORE<br></br>MIDDLE EASTERN<br className="web-view-break"/>JOURNEYS </> )} 
               secSettings={sec5SlideSettings}></BlogSlider>
         </div>

         
         
      </div>
      

     
      


    </>
  )
}

export default DestinationMEast
