import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import ScrollToTop from '../ScrollToTop';
import './Destination.css'
import BlogSlider from '../Home/HomeBlogSlider';
import TextTransition from './TextTransition';


const DestinationEurope = () => {
   

   const scrollTop = () =>{
      window.scrollTo(0,0);
   }
   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }
   const sec3Data = [
      {
         image_url: "/images/europe/sec-3/1.png",
         title: "PARIS",
         description: "Eiffel Tower",
         link:"https://youtu.be/RksdZWD4Udg?si=AU5SpxylAzJwcuyu"
      },
      {
         image_url: "/images/europe/sec-3/2.png",
         title: "SWITZERLAND",
         description: "Paradise on the Earth",
         link:"https://youtu.be/VSHbyktxcU4?si=BNY9i_3EjbFEYEBA"
      }
   ]
   const sec3SlideSettings = {
      dots: false,
      infinite: true,
      autoplay: false,
      // autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 3
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:2
            }
         },
         {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
      ],
      arrows:false
   };


  const sec5Data = [
    {
       image_url: "/images/europe/sec-5/1.png",
       title: "LUXEMBOURG ",
       description: "Enjoy The Scenic Beauty ",
       link:"https://youtu.be/7r6IjDN2b_w?si=G084gnLuQX1qCfrN"
    },
    {
      image_url: "/images/europe/sec-5/2.png",
       title: "NORWAY ",
       description: "Most Dangerous Road ",
       link:"https://youtu.be/ElobS58_QSU?si=IJ_IeAVIlTepf6Do"
    },
    {
      image_url: "/images/europe/sec-5/3.png",
      title: "FRANCE ",
      description: "Exploring The Village ",
      link:"https://youtu.be/9h39dKGalzA?si=80jyD6G0zZheCr7B"
   },
      {
      image_url: "/images/europe/sec-5/4.png",
      title: "BELGIUM ",
      description: "Best Chocolate in The World ",
      link:"https://youtu.be/VRBhRRyMvCE?si=EvyHBdROKLIk2yYz"
   },
   {
      image_url: "/images/europe/sec-5/5.png",
      title: "NETHERLAND  ",
      description: "No Politics No Religion",
      link:"https://youtu.be/nOJLweP0SLc?si=a3sndE5PX05U7zkH"
   }

  ]

  // Custom previous and next arrows
   const PrevArrow = (props) => {
      const { className, onClick } = props;
      return (
      <div className={`custom-prev-arrow ${className}`} onClick={onClick}>
         Previous
      </div>
      );
   };

   const NextArrow = (props) => {
      const { className, onClick } = props;
      return (
      <div className={`custom-next-arrow ${className}`} onClick={onClick}>
         Next
      </div>
      );
   };

   
   const sec5SlideSettings = {
   variableWidth: true,
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow:1
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ]
 };
  //  BANNER EFFECT
  const [scrollOpacity, setScrollOpacity] = useState(1);
  const [scrollScale, setScrollScale] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxOpacityScroll = 400; 
      const maxZoomScroll = 800; 

      // Calculate opacity based on scroll position
      const opacity = 1 - (scrollPosition / maxOpacityScroll);
      setScrollOpacity(opacity < 0 ? 0 : opacity);

      // Calculate scale based on scroll position
      const scale = 1 + (scrollPosition / maxZoomScroll) * 0.2; 
      setScrollScale(scale);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
    useEffect(() => {
      const images = document.querySelectorAll('.dest-section-1 img');
      images.forEach(img => {
        img.style.opacity = scrollOpacity;
        img.style.transform = `scale(${scrollScale})`;
      });
    }, [scrollOpacity, scrollScale]);
    


  return (
    <>
       
       <div class="dest-section-1 img_container top_img">
         <img alt='' className='hidden hidden-md' src="/images/europe/banner/1.png" />
            <img alt='' className='hidden-lg hidden' src="/images/europe/banner/1-md.png" />
            <img alt='' className='hidden-lg hidden-md' src="/images/europe/banner/1-sm.png" />
          <div class="banner-content" >
            <h1 >TRAVEL IN<br/><span>Europe</span></h1>
            <p className='text-white text-align-justify mt-4'>Explore Europe's beauty with our tour package, discovering historic cities, scenic landscapes, and cultural treasures for unforgettable experiences.</p>
            <Link to="/contact" onClick={scrollTop}><button className="btn btn-primary destination-contact"> 
                  Contact Us
                  
            </button></Link>
          </div>
      </div>

      {/* CARD */}
      <div className="home-sec-5" style={{
               backgroundImage: 'url(/images/Blog-bg/europe-blog-bg.png)',
               // marginBottom: '6rem',
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               WebkitBackgroundSize: "cover",
               MozBackgroundSize: "cover",
               OBackgroundSize: "cover",
               paddingTop:"6rem"
            
            }}>

         <div className="slide-container">
            <div className="row sec-title mt-5 mb-5 hidden-lg hidden-md" >
               <div className="col-12">
                  <h2 className="yellow-line mt-5" >EXPLORE<br/>EUROPEAN<br/>ADVENTURES<br/>THROUGH MY<br/>VLOGS</h2>
               </div>
            </div>
            <BlogSlider secData={sec5Data} 
               secTitle={( <> EXPLORE EUROPEAN<br className="web-view-break"/>ADVENTURES THROUGH MY VLOGS </> )} 
               secSettings={sec5SlideSettings}></BlogSlider>
         </div>

         
         
      </div>
         

      <div class='dest-section-4'>
         <img alt='' className='sec2-img hidden hidden-md' src='/images/europe/sec-4/1.png' />
         <img alt='' className='sec2-img hidden-lg hidden' src='/images/europe/sec-4/1-md.png' />
         <img alt='' className='sec2-img hidden-lg hidden-md' src='/images/europe/sec-4/1-sm.png' />
         <div className='sec-title'>
            <div className='yellowline'></div>
            <h1 className="hidden-md hidden">EUROPEAN<br/>WONDERS UNVEILED </h1>
            <h1 className="hidden-lg">EUROPEAN WONDERS<br></br>UNVEILED </h1>
            <p>
            Embark on a journey through Europe's most captivating landmarks and hidden gems. From architectural marvels to natural wonders, this curated collection showcases the continent's diverse tapestry of beauty and heritage.  <br/>
                  Join us on a visual odyssey as we uncover the mesmerising wonders that define the heart and soul of Europe. </p>           
         </div>
      </div>
      
      <div class='dest-section-3'>
         <div className='sec-content'>
            <TextTransition text="Europe is a tapestry of enchanting landscapes, where ancient charm meets modern allure beneath skies adorned with the whispers of history,art, and culture.">

            </TextTransition>
         </div>
      </div>


      <div class="dest-section-2 main-section">
         <div className='sec-title'>
            <h1 className="">DISCOVER MORE<br></br>EUROPEAN<br/>TREASURES </h1>
            <div className='blackline'></div>
         </div>
         
         <div className='sec-card'>
            <Link to="https://youtu.be/RksdZWD4Udg?si=AU5SpxylAzJwcuyu" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md ' src='/images/europe/sec-2/1.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/europe/sec-2/1-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/europe/sec-2/1-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">PARIS</div>
                  <div className="description">Eiffel Tower</div>
                  <div className="line"></div>
               </div>
            </Link>
            <Link to="https://youtu.be/VSHbyktxcU4?si=BNY9i_3EjbFEYEBA" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md' src='/images/europe/sec-2/2.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/europe/sec-2/2-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/europe/sec-2/2-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">SWITZERLAND</div>
                  <div className="description">Paradise on the Earth</div>
                  <div className="line"></div>
               </div>
            </Link>
         </div>
         
      </div>

      

     
      


    </>
  )
}

export default DestinationEurope
