import React from 'react'
import "./base.css";
import CommonSection from '../common-componets/CommonSection';
import {  Link } from "react-router-dom";


const Cart = () => {
  return (
    <>
    
<CommonSection className={' shopSub-sec-2'}>
      <div className='container shopSub2-sec-2'>
            <div className='row d-lg-flex pt-lg-4 pb-lg-2 hidden hidden-md'>
                <div className='col-1 me-2  '>
                    <p>Home</p>
                </div>
                <div className='col-1'>
                    <img src="/images/arrow.svg" alt=""></img>
                </div>
                <div className='col-1 me-2 '>
                    <p>Shop</p>
                </div>
                <div className='col-1 '>
                    <img src="/images/arrow.svg" alt=""></img>
                </div>
                <div className='col-1 '>
                    <img src="/images/line.svg" alt=""></img>
                </div>
                <div className='col-2 '>
                    <h5>Cart </h5>
                </div>
            
            </div>
      </div>
    </CommonSection>
    <CommonSection className={"cart-1 pb-4"}>
        <div className='container cart-sec-2 pt-5 pb-5'>
            <div className='row'>
                <div className='col-8 hidden-md '>
                    <div className='row pt-3 cart-sec-2-row hidden  '>
                        <div className='col-3'>
                            <h3>Product</h3>
                        </div>
                        <div className='col-3'>
                            <h3 className='price'>Price</h3>
                        </div>
                        <div className='col-3'>
                            <h3 className='quantity'>Quantity</h3>
                        </div>
                        <div className='col-3'>
                            <h3 className='total'>SubTotal</h3>
                        </div>
                    </div>

                    <div className='row mt-5 hidden justify-content-center'>
                        <div className='col-1 cart-sec-2-img'>
                            <img src="/images/shop_sub2/sec-4/outdoor.png" alt=""></img>
                        </div>
                        <div className='col-3 mt-3'>
                            <h4>Dyssa derede.</h4>
                        </div>
                        <div className='col-3 mt-3'>
                            <h5>￡10.00</h5>
                        </div>
                        <div className='count col-3'>
                            
                                <button>-</button>
                                <input value={(0)}/>
                                <button>+</button>
                            
                        </div>
                        <div className='ms-5 col-2 d-flex justify-content-end mt-3'>
                            <h5>￡10.00.</h5>
                        </div>
                    </div>
                </div>


           
                <div className='cart-sec-4 hidden-lg hidden-md'>
                    <div className='row'>
                        <div className='col-1 col-md-2 cart-sec-2-img ms-2'>
                                <img src="/images/shop_sub2/sec-4/outdoor.png" alt="" className='mt-3'></img>
                        </div>
                        <div className='col-6 col-md-8 ms-3'>
                            <h4>Dyssa derede.</h4>
                            <h5>￡10.00.</h5> 
                            <div className='count mt-4 ms-3'>
                            
                            <button>-</button>
                            <input value={(0)}/>
                            <button>+</button>
                        
                    </div>
                        </div>  
                        <div className='col-1 cart-sec-4-img'>
                            <img src="/images/shop_sub2/sec-4/close-square.svg" alt=""></img>
                        </div>  
                    </div>
                </div>
           

                <div className='cart-sec-4 hidden-lg hidden'>
                    <div className='row'>
                        <div className='col-3 cart-sec-2-img ms-2'>
                                <img src="/images/shop_sub2/sec-4/outdoor.png" alt="" className='mt-3'></img>
                        </div>
                        <div className='col-7 ms-4'>
                            <h4>Dyssa derede.</h4>
                            <h5>￡10.00.</h5> 
                            <div className='count mt-4 ms-5'>
                            
                            <button>-</button>
                            <input value={(0)}/>
                            <button>+</button>
                        
                    </div>
                        </div>  
                        <div className='col-1 cart-sec-4-img'>
                            <img src="/images/shop_sub2/sec-4/close-square.svg" alt=""></img>
                        </div>  
                    </div>
                </div>


                <div className='col-lg-4 col-md-12 col-sm-12 px-5 pb-5 bg'>
                    <h2 className='mt-4 mb-5'>Cart Totals</h2>
                    <div className='row cart-sec-3'>
                      
                        <div className='col-6 '>
                            <h4>Subtotal</h4>
                            <h4>Total</h4>

                         </div>
                        <div className='col-6'>
                            <h6>￡10.00</h6>
                            <h5>￡10.00</h5>
                        </div> 
                    </div>
                    <div className='row'>
                        <div className='col-12 mt-2 mb-2'>
                            <Link to="/checkout"><button className='btn btn-primary mx-auto'>Check out</button></Link>
                        </div>
                </div>
                </div>
                
            </div>
        </div>
    </CommonSection>




    <CommonSection className={'shopSub-sec-4'}>
      <div className='container flex-column d-flex pt-5 pb-4'>
         <div className='row '>
            <div className='col-1'>
                  <img src="/images/trophy 1.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>High Quality</h6>
               <p>crafted from top materials</p>
            </div>
            <div className='col-1'>
                  <img src="/images/guarantee.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>Warranty Protection</h6>
               <p>over 2 years</p>
            </div>
            <div className='col-1'>
                  <img src="/images/shipping.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>Free Shipping</h6>
               <p>Order over 150 $</p>
            </div>
            <div className='col-1'>
                  <img src="/images/customer-support.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>24 / 7 Support</h6>
               <p>Dedicated support</p>
            </div>      
         </div>
      </div>
    </CommonSection>






    </>
  )
}

export default Cart
