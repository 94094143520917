import React from 'react'
import CommonSection from "../common-componets/CommonSection";
import {  Link } from "react-router-dom";


const Section6 = () => {
const scrollTop = () =>{
    window.scrollTo(0,0);
    }

  return (
    <CommonSection className="container home-sec-6">
            <div className="row align-items-center flex-column">
               <div className="col-12 d-flex justify-content-lg-center  justify-content-md-center  mt-5 mb-3">
                     <h1 className="footer-title">Travel<span>.</span> Capture<span>.</span> Share<span>.</span></h1>
               </div>
               <div className="col-12 d-flex  justify-content-lg-center  justify-content-md-center justify-content-sm-start  mb-2">
                  <p className='footer-desc hidden-md' style={{fontSize:"14px"}}>The world is our playground, and every adventure with Travelista will be an unforgettable<br></br>chapter in your travel story. </p>
                  <p className='footer-desc hidden-lg hidden' style={{fontSize:"14px"}}>The world is our playground, and every adventure with Travelista will be an unforgettable chapter in your travel story. </p>
               </div>
               <div className="col-12 d-flex  justify-content-lg-center  justify-content-md-center justify-content-sm-start  mt-2 mb-lg-5 plan-txt">
                  <button className="btn btn-primary me-2 mb-3 travel-btn" onClick={scrollTop}>Plan your Travel</button>
                  <Link to ="/contact" onClick={scrollTop}><button className="btn btn-primary  contact-btn"> <span className="contact-img"></span>  Contact</button></Link>
               </div>
            </div>
         </CommonSection>
  )
}

export default Section6