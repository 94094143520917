import {React,useState} from 'react'
import { videoUrls } from "../constants/galleryVideos";
import Slider from "react-slick";
import "./GallerySlider.css"

import Masonry,{ResponsiveMasonry} from 'react-responsive-masonry';


const GalleryVideoSlider = () => {
    

    const sec2Data = videoUrls
    const videos = videoUrls.asia

    const [currentSlide, setCurrentSlide] = useState(0);

    const sec2SlideSettings = {
        dots: false,
        arrows:true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow:1
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow:1
            }
        }
        ],
        afterChange: (current) => {
            setCurrentSlide(current);
        }
    };

    const flattenedVideos = videos.flat();
    const columnCountBreakPoints = { 350: 1, 750: 1, 900: 3 }
   


  return (
    <div>
        <div className='container gallery-sec-2 align-items-center justify-content-center '>
      
        {/* <div className="row mb-2 img-video">
            <p><span className='img'>IMAGES </span ><span className='video'>/ VIDEOS    </span> </p>
        </div>   */}
        
        <div className='row gallery-imgs'>
            <ResponsiveMasonry columnsCountBreakPoints={columnCountBreakPoints}>
                <Masonry gutter='20px'>
                {flattenedVideos.map((video, i) => (
                <video key={i} controls style={{ width: '100%', display: 'block' }}>
                    <source src={video} />
                </video>
                ))}
                </Masonry>
            </ResponsiveMasonry>
        </div>

        
        
      </div>
    </div>
  )
}

export default GalleryVideoSlider