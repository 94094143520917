export const imageUrls = {
    "asia": [
        [
            
            "/images/gallery/Arun photo website/IMG_4885.JPG",
            "/images/gallery/Travelista Files/20210703_170537.jpg",
            "/images/gallery/Arun photo website/IMG_3557.jpg",
            "/images/gallery/Arun photo website/IMG_4892.JPG",
            "/images/gallery/Arun photo website/IMG_4889.JPG",
            "/images/gallery/Arun photo website/IMG_4888.JPG",
            "/images/gallery/Arun photo website/PXL_20230704_191058401.jpg",
            "/images/gallery/Travelista Files/20210703_154648.jpg",
            "/images/gallery/Arun photo website/PXL_20230704_175557813.jpg",
            "/images/gallery/Travelista Files/20210416_163737.jpg",
            "/images/gallery/Travelista Files/20210411_115954.jpg",
            
            "/images/gallery/Arun photo website/PXL_20230701_151140671.PORTRAIT.jpg",
        ],
        [
            
            "/images/gallery/Arun photo website/IMG_4890.JPG",
            "/images/gallery/Arun photo website/f9f58a29-8958-4586-80e2-29f9b05400be.jpg",
            "/images/gallery/Arun photo website/B4470E33-0051-4CE5-944C-044355615DC9.jpg",
            "/images/gallery/Arun photo website/IMG_4891.JPG"
        ],
        [
            "/images/gallery/Arun photo website/f52ec0f9-bbd2-4841-b98e-3128b2ff8718.jpg",
            "/images/gallery/Arun photo website/IMG_2974.jpg",
            "/images/gallery/Arun photo website/343506c3-6a85-4589-8be9-0ba90629828f.jpg",
            "/images/gallery/Arun photo website/IMG_9986.JPG",
        ],
        [
            "/images/gallery/Arun photo website/IMG_9986.JPG",
            "/images/gallery/Arun photo website/PXL_20230628_125731702.PORTRAIT.jpg",
            "/images/gallery/Arun photo website/PXL_20230628_125850786.jpg",
            "/images/gallery/Arun photo website/PXL_20230628_141547189.PORTRAIT.jpg",
            "/images/gallery/Arun photo website/PXL_20230628_142104260.jpg"
        ],
        [
            "/images/gallery/Arun photo website/PXL_20230704_191128498.PORTRAIT.jpg",
            "/images/gallery/Arun photo website/PXL_20230704_210537515.PORTRAIT.jpg",
            "/images/gallery/Arun photo website/PXL_20230704_213139118.jpg",
            "/images/gallery/Arun photo website/PXL_20230704_214152039.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_145125460.PORTRAIT.ORIGINAL.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_145128761.PORTRAIT.ORIGINAL(1).jpg"
        ],
        [
            "/images/gallery/Arun photo website/PXL_20230705_145128761.PORTRAIT.ORIGINAL.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_145131027.PORTRAIT.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_150030402.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_150033435.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_150517807.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_150521308.jpg",
            "/images/gallery/Arun photo website/PXL_20230704_180738614.jpg",
        ],
        // [
        //     "/images/gallery/sec-1/3.png",
        //     "/images/gallery/sec-1/4.png",
        //     "/images/gallery/sec-1/5.png",
        //     "/images/gallery/sec-1/6.png",
        //     "/images/gallery/sec-1/7.png",
        // ],
        [
            "/images/gallery/Arun photo website/PXL_20230705_165932833.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_165949530.PORTRAIT.ORIGINAL.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_170001732.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_181248755.jpg",
            "/images/gallery/Arun photo website/PXL_20230705_190813499.jpg",
            "/images/gallery/Arun photo website/PXL_20230706_121127785.PORTRAIT.jpg"
        ],
        [
            "/images/gallery/Arun photo website/PXL_20230706_130034829.jpg",
            "/images/gallery/Arun photo website/PXL_20230706_142825024.jpg",
            "/images/gallery/Arun photo website/PXL_20230708_162424399.PORTRAIT.jpg",
            "/images/gallery/Arun photo website/PXL_20230708_172228634.jpg",
            "/images/gallery/Arun photo website/IMG_3890.PNG",
            // "/images/gallery/sec-1/3mobile.png"
        ],
        // [
        //     "/images/gallery/sec-1/3tab.png",
        //     "/images/gallery/sec-1/4mobile.png",
        //     "/images/gallery/sec-1/4tab.png",
        //     "/images/gallery/sec-1/5mobile.png"
        // ],
        // [
        //     "/images/gallery/sec-1/5tab.png",
        //     "/images/gallery/sec-1/6mobile.png",
        //     "/images/gallery/sec-1/6tab.png",
        //     "/images/gallery/sec-1/7mobile.png"
        // ],
        [
            // "/images/gallery/sec-1/7tab.png",
            "/images/gallery/Travelista Files/04.png",
            "/images/gallery/Travelista Files/11.png",
            "/images/gallery/Travelista Files/20210411_101540.jpg",
            "/images/gallery/Travelista Files/20210411_102812.jpg",
            "/images/gallery/Travelista Files/20210411_115412(0).jpg",
            "/images/gallery/Arun photo website/PXL_20230628_151052172.jpg",
            "/images/gallery/Arun photo website/PXL_20230704_183930811.jpg",
        ],
        [
            
            
            "/images/gallery/Travelista Files/20210703_173053.jpg",
            "/images/gallery/Travelista Files/2619AC4A-AB48-4AC3-AADF-6E3C0AD2C55A.JPG"
        ],
        [
            "/images/gallery/Travelista Files/90B2C579-1D18-41B6-AC62-729B84088B4B.JPG",
            "/images/gallery/Travelista Files/B4470E33-0051-4CE5-944C-044355615DC9.JPG",
            "/images/gallery/Travelista Files/File 1.png",
            "/images/gallery/Travelista Files/File 2.png",
            "/images/gallery/Travelista Files/File 3.png",
            "/images/gallery/Travelista Files/IMG_2600.JPG"
        ],
        [
            "/images/gallery/Travelista Files/IMG_2615.JPG",
            "/images/gallery/Travelista Files/IMG_6847.JPG",
            "/images/gallery/Travelista Files/PXL_20230627_213414241.PORTRAIT.JPG",
            "/images/gallery/Travelista Files/PXL_20230628_135902101.PORTRAIT.jpg",
            "/images/gallery/Travelista Files/PXL_20230701_151246465.PORTRAIT.jpg",
            "/images/gallery/Travelista Files/PXL_20230701_151334383.JPG"
        ],
        [
            "/images/gallery/Travelista Files/PXL_20230704_153111366.PORTRAIT.ORIGINAL.JPG",
            "/images/gallery/Travelista Files/PXL_20230704_153809291.PORTRAIT.ORIGINAL.JPG",
            "/images/gallery/Travelista Files/PXL_20230704_154319713.PORTRAIT.ORIGINAL.JPG",
            "/images/gallery/Travelista Files/PXL_20230704_170227345.PORTRAIT.JPG",
            "/images/gallery/Travelista Files/PXL_20230704_175924452.PORTRAIT.ORIGINAL.JPG",
            "/images/gallery/Travelista Files/PXL_20230704_191128498.PORTRAIT.JPG"
        ],
        [
            "/images/gallery/Travelista Files/PXL_20230704_210459566.jpg",
            "/images/gallery/Travelista Files/PXL_20230704_215448966.PORTRAIT.ORIGINAL.JPG",
            "/images/gallery/Travelista Files/PXL_20230705_145128761.PORTRAIT.ORIGINAL.jpg",
            "/images/gallery/Travelista Files/PXL_20230705_174504885.jpg",
            "/images/gallery/Travelista Files/PXL_20230705_211040167.JPG",
            "/images/gallery/Travelista Files/PXL_20230705_211342370.JPG"
        ],
        [
            "/images/gallery/Travelista Files/PXL_20230706_121127785.PORTRAIT.jpg",
            "/images/gallery/Travelista Files/PXL_20230708_165536352.PORTRAIT.JPG",
            "/images/gallery/Travelista Files/PXL_20230708_170531512.PORTRAIT.JPG"
        ],
        [
            "/images/gallery/JPEG/FullSizeRender 3.jpg",
            "/images/gallery/JPEG/FullSizeRender 7.jpg",
            "/images/gallery/JPEG/FullSizeRender 9.jpg",
            "/images/gallery/JPEG/IMG_0279 2.jpg",
            "/images/gallery/JPEG/IMG_0475.jpg",
            "/images/gallery/JPEG/IMG_0530.jpg",
            "/images/gallery/JPEG/IMG_0533.jpg",
            "/images/gallery/JPEG/IMG_0653.jpg",
            "/images/gallery/JPEG/IMG_1179.jpg"
        ],
        [
            "/images/gallery/JPEG/IMG_1849.jpg",
            "/images/gallery/JPEG/IMG_2121.jpg",
            "/images/gallery/JPEG/IMG_2212.jpg",
            "/images/gallery/JPEG/IMG_2234.jpg",
            "/images/gallery/JPEG/IMG_2250.jpg",
            "/images/gallery/JPEG/IMG_2423.jpg",
            "/images/gallery/JPEG/IMG_2996.jpg",
            "/images/gallery/JPEG/IMG_3246.jpg",
            "/images/gallery/JPEG/IMG_3266.jpg"
        ],
        [
            "/images/gallery/JPEG/IMG_3780.jpg",
            "/images/gallery/JPEG/IMG_3807.jpg",
            "/images/gallery/JPEG/IMG_3809.jpg",
            "/images/gallery/JPEG/IMG_3822.jpg",
            "/images/gallery/JPEG/IMG_3832.jpg",
            "/images/gallery/JPEG/IMG_3852.jpg",
            "/images/gallery/JPEG/IMG_3978.jpg",
            "/images/gallery/JPEG/IMG_3987.jpg",
            "/images/gallery/JPEG/IMG_4119.jpg"
        ],
        [
            "/images/gallery/JPEG/IMG_4618.jpg",
            "/images/gallery/JPEG/IMG_4718.jpg",
            "/images/gallery/JPEG/IMG_5079.jpg",
            "/images/gallery/JPEG/IMG_5343.jpg",
            "/images/gallery/JPEG/IMG_5465.jpg",
            "/images/gallery/JPEG/IMG_6979.jpg",
            "/images/gallery/JPEG/IMG_7659.jpg",
            "/images/gallery/JPEG/IMG_7660.jpg",
            "/images/gallery/JPEG/IMG_8006.jpg"
        ],
        [
            "/images/gallery/JPEG/IMG_8299.jpg",
            "/images/gallery/JPEG/IMG_8665.jpg",
            "/images/gallery/JPEG/IMG_9053.jpg",
            "/images/gallery/JPEG/IMG_9161.jpg",
            "/images/gallery/JPEG/IMG_9219.jpg",
            "/images/gallery/JPEG/IMG_9725.jpg",
            "/images/gallery/JPEG/IMG_9778.jpg",
            "/images/gallery/JPEG/IMG_9839.jpg"
        ]
    ]
}