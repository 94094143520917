import React, { useState ,useEffect} from 'react'
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import ScrollToTop from '../ScrollToTop';
import './Destination.css'
import BlogSlider from '../Home/HomeBlogSlider';
import TextTransition from './TextTransition';


const DestinationLAmerica = () => {
   

   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }


  const sec5Data = [
    {
       image_url: "/images/america/sec-5/1.png",
       title: "PERU",
       description: "UROS Tribal Floating Islands ",
       link:"https://youtu.be/bf_jA8iTRcc?si=y2xjZv0XsmExutt3 "
    },
    {
      image_url: "/images/america/sec-5/2.png",
       title: "BOLIVIA ",
       description: "Peru-Bolivia Border Crossing ",
       link:"https://youtu.be/RJzS5xqQlq4?si=GUZxpH6y4gA0nRaw "
    },
    {
      image_url: "/images/america/sec-5/3.png",
      title: "RIO DE JANEIRO ",
      description: "To the Land of Celebrations ",
      link:"https://youtu.be/c7ut9SkI1oo?si=r8xpHU5T5LpQspek "
   },
      {
      image_url: "/images/america/sec-5/4.png",
      title: "USHUAIA ",
      description: "End of the World ",
      link:"https://youtu.be/BDx5EEuj2Us?si=mOqWE18mz2wOBffp "
   },
   {
      image_url: "/images/america/sec-5/5.png",
      title: "BOLIVIA  ",
      description: "Gold Smuggling Routes ",
      link:"https://youtu.be/WIMu76E0Ynk?si=FdGrtOE2d-htexEu "
   }

  ]
  const scrollTop = () =>{
   window.scrollTo(0,0);
}
   const sec3Data = [
      {
         image_url: "/images/america/1.png",
         title: "MACHU PICCHU",
         description: "Near Death Experience",
         link:"https://youtu.be/LhM6Z8V_ZPc?si=Uj0eVU182_bzfaJG"
      },
      {
         image_url: "/images/america/2.png",
         title: "AMAZON",
         description: "Anaconda Hunting",
         link:"https://youtu.be/0Z0pX4MMf70?si=4wT8JIvdUyWo-WfA"
      }
   ]
   const sec3SlideSettings = {
      dots: false,
      infinite: true,
      autoplay: false,
      // autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 3
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:2
            }
         },
         {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
      ],
      arrows:false
   };

   // Custom previous and next arrows
   const PrevArrow = (props) => {
      const { className, onClick } = props;
      return (
        <div className={`custom-prev-arrow ${className}`} onClick={onClick}>
          Previous
        </div>
      );
    };
    
    const NextArrow = (props) => {
      const { className, onClick } = props;
      return (
        <div className={`custom-next-arrow ${className}`} onClick={onClick}>
          Next
        </div>
      );
    };
      
   const sec5SlideSettings = {
   prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    variableWidth:true,
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow:1
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ]
 };

  //  BANNER EFFECT
  const [scrollOpacity, setScrollOpacity] = useState(1);
  const [scrollScale, setScrollScale] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxOpacityScroll = 400; 
      const maxZoomScroll = 800; 

      // Calculate opacity based on scroll position
      const opacity = 1 - (scrollPosition / maxOpacityScroll);
      setScrollOpacity(opacity < 0 ? 0 : opacity);

      // Calculate scale based on scroll position
      const scale = 1 + (scrollPosition / maxZoomScroll) * 0.2; 
      setScrollScale(scale);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
    useEffect(() => {
      const images = document.querySelectorAll('.dest-section-1 img');
      images.forEach(img => {
        img.style.opacity = scrollOpacity;
        img.style.transform = `scale(${scrollScale})`;
      });
    }, [scrollOpacity, scrollScale]);
    

 
  return (
    <>
       
       <div class="dest-section-1 img_container top_img">
            <img alt='' className='hidden hidden-md' src="/images/america/banner/1.png" />
            <img alt='' className='hidden-lg hidden' src="/images/america/banner/1-md.png" />
            <img alt='' className='hidden-lg hidden-md' src="/images/america/banner/1-sm.png" />
          <div class="banner-content" >
            <h1 >TRAVEL IN<br/><span>Latin<br/>America</span></h1>
            <p className='text-white text-align-justify mt-4'>We welcome you to explore Latin America's vibrant cultures, stunning scenery, and fascinating history, creating unforgettable memories to cherish.</p>
            <Link to="/contact" onClick={scrollTop}><button className="btn btn-primary destination-contact"> 
                  Contact Us
                  
            </button></Link>
          </div>
      </div>

      <div class="dest-section-2 main-section">
         <div className='sec-title'>
            <h1 className="">DISCOVER<br/>LATIN AMERICAN<br></br>Journey Through<br/>My Vlogs </h1>
            <div className='blackline'></div>
         </div>
         
         <div className='sec-card'>
            <Link to="https://youtu.be/LhM6Z8V_ZPc?si=Uj0eVU182_bzfaJG" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md ' src='/images/america/sec-2/1.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/america/sec-2/1-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/america/sec-2/1-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">MACHU PICCHU</div>
                  <div className="description">Near Death Experience</div>
                  <div className="line"></div>
               </div>
            </Link>
            <Link to="https://youtu.be/0Z0pX4MMf70?si=4wT8JIvdUyWo-WfA" target='_blank'>
               <div className="image-container">
                  <img alt='' className='hidden hidden-md' src='/images/america/sec-2/2.png' />
                  <img alt='' className='hidden-lg hidden' src='/images/america/sec-2/2-md.png' />
                  <img alt='' className='hidden-lg hidden-md' src='/images/america/sec-2/2-sm.png' />
                  <div className="overlay"></div>
                  <div className="title">AMAZON</div>
                  <div className="description">Anaconda Hunting</div>
                  <div className="line"></div>
               </div>
            </Link>
         </div>
         
      </div>
      
      <div class='dest-section-3'>
         <div className='sec-content'>
            <TextTransition text="Latin America's beauty is a symphony of nature's wonders, a celebration of diverse cultures, and a testament to resilience—a place where every corner invites exploration and leaves an indelible mark on the heart. ">
            </TextTransition>
         </div>
      </div>

      {/* CARD */}
      <div className=" home-sec-5" style={{
               backgroundImage: 'url(/images/Blog-bg/latinamerica-blog-bg.png)',
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               WebkitBackgroundSize: "cover",
               MozBackgroundSize: "cover",
               OBackgroundSize: "cover",
               paddingTop:"6rem"
            
            }}>

         <div className="slide-container">
            <div className="row sec-title mt-5 mb-5 hidden-lg hidden-md" >
               <div className="col-12">
                  <h2 className="yellow-line mt-5" >EXPLORE MORE<br/>LATIN AMERICAN<br/>ADVENTURES</h2>
               </div>
            </div>
            <BlogSlider secData={sec5Data} 
               secTitle={( <> EXPLORE MORE<br className="web-view-break"/>LATIN AMERICAN<br className="web-view-break"/>ADVENTURES </> )} 
               secSettings={sec5SlideSettings}></BlogSlider>
         </div>

         
         
      </div>
      
      
      <div class='dest-section-4'>
         <img alt='' className='sec2-img hidden hidden-md' src='/images/america/sec-4/1.png' />
         <img alt='' className='sec2-img hidden-lg hidden' src='/images/america/sec-4/1-md.png' />
         <img alt='' className='sec2-img hidden-lg hidden-md' src='/images/america/sec-4/1-sm.png' />
         <div className='sec-title'>
            <div className='yellowline'></div>
            <h1 className="hidden-md hidden">EMBRACING<br></br>ADVENTURES &<br/>CULTURE</h1>
            <h1 className="hidden-lg">EMBRACING<br></br>ADVENTURES & CULTURE</h1>
            <p>
            Dive into the heart of Latin America – where history, culture, and endless adventures collide. From the ancient remnants of Machu Picchu in Peru to the lively beats of Rio de Janeiro's Carnival in Brazil, this region is a treasure trove of unforgettable experiences.  
            
                  Nature enthusiasts will revel in the breathtaking beauty of the Amazon rainforest, home to an astonishing array of wildlife and indigenous cultures. Get ready to pack your bags and dive into the pulse of Latin America - where every moment sparks a new adventure and leaves you craving for more. 
            </p>           
         </div>
      </div>
      
      


    </>
  )
}

export default DestinationLAmerica
