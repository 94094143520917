import React, { useEffect,useState } from 'react';
import './Text.css'; 


const TextTransition = (props) => {
    const [glowText, setGlowText] = useState('');
  
    useEffect(() => {
      const text = props.text
      const generateGlowingText = () => {
        const animationDuration = 1; // Duration of the glow animation
        const animationDelayBase = 0.1; // Base delay between each letter
        const animationDelayIncrement = 0.1; // Incremental delay for each letter to avoid overlap
        
        const glowingText = text.split('').map((char, index) => {
          const animationDelay = (index * animationDelayIncrement) % (text.length * animationDelayBase); // Adjust delay for each letter to avoid overlap
          return (
            <span key={index} style={{ animationDelay: `${animationDelay}s`, animationDuration: `${animationDuration}s` }}>
              {char}
            </span>
          );
        });
        setGlowText(glowingText);
      };
  
      generateGlowingText();
    }, []);
  
    return (
        <div className="mast__title">
             <p>{glowText}</p> 
        </div>
        
    );
  };
  
  export default TextTransition;