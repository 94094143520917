import React from 'react'
import "./base.css";
import CommonSection from '../common-componets/CommonSection';

const Checkout = () => {
  return (
    <>
      
    <CommonSection className={' shopSub-sec-2'}>
            <div className='container shopSub2-sec-2'>
                    <div className='row d-lg-flex pt-lg-4 pb-lg-2 hidden hidden-md'>
                        <div className='col-1 me-2'>
                            <p>Home</p>
                        </div>
                        <div className='col-1'>
                            <img src="/images/arrow.svg" alt=""></img>
                        </div>
                        <div className='col-1 me-2'>
                            <p>Shop</p>
                        </div>
                        <div className='col-1'>
                            <img src="/images/arrow.svg" alt=""></img>
                        </div>
                        <div className='col-2'>
                            <h5>Cart </h5>
                        </div>
                    
                    </div>
            </div>
        </CommonSection>
        <CommonSection className={"checkout-sec-2"}>
            <div className='container pt-5'>
                <div className='row'>
                    <h1 className='pt-3 mb-5'>Billing Details</h1>
                </div>
              
            </div>
        </CommonSection>

    <CommonSection className={"checkout-sec-3 pb-5"}>
    <div class="container align-items-left justify-content-start mb-3">
        <div className='row'>
            <div className='col-lg-6 col-md-12 col-sm-12'>
                <form className='form'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <label for="fname">First Name</label>
                            <input type="text" id="fname"  name="firstname" className="input-box" required />
                        </div>
                        <div className='col-lg-6 col-md-6  col-sm-12'>
                            <label for="lname">Last Name</label>
                            <input type="text" id="lname" name="lastname" className="input-box" required/>
                        </div>
                      
                    </div>
                    <label for="cname">Company Name (Optional)</label>
                    <input type="text" className="input-box" name="companyname" required/><br></br>

                    <label for="country">Country/Region</label>
                    <input type="text" className="input-box" name="country" required/><br></br>

                    <label for="adr">Street Address</label>
                    <input type="text" className="input-box" name="address"  required/><br></br>

                    <label for="town">Town</label>
                    <input type="text" className="input-box" name="town"  required/><br></br>

                    <label for="zip">ZIP code</label>
                    <input type="text" className="input-box" name="zip"  required/><br></br>

                    <label for="phone">Phone</label>
                    <input type="text" className="input-box" name="phone"  required/><br></br>

                    <label for="email">Email Address</label>
                    <input type="text" className="input-box" name="email"  required/><br></br>

                </form>
            </div>
            <div className='col-lg-6 col-sm-12 '>
                <div className='row checkout-col-1'>
                    <div className='col-6'>
                        <h2>Product</h2>
                        <p>Dyssa derede.<span className='ms-2'> x 1</span></p>
                        <h4>Subtotal</h4>
                        <h4>Total</h4>

                    </div>
                    <div className='col-6'>
                        <h3>Subtotal</h3>
                        <h6>￡10.00</h6>
                        <h6>￡10.00</h6>
                        <h5>￡10.00</h5>

                    </div>
                </div>
                <hr className="solid checkout-col ms-5"/>
                <div className='row checkout-col'>
                    <div className='col-1'>
                    <input type="radio" value="bank"/>
                    </div>
                    <div className='col-11'>
                    <label for="bank">Direct Bank Transfer</label>
                    </div>
                            
                    <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference.
                        Your order will not be shipped until the funds have cleared in our account.</p>
                    <div className='col-1'>
                        <input type="radio" value="bank"/>
                    </div>
                    <div className='col-11'>
                        <label for="bank">Direct Bank Transfer</label>
                    </div>
                    <div className='col-1'>
                        <input type="radio" value="cash" className='radio'/>
                    </div>
                    <div className='col-11'>
                        <label for="cash" className='mt-4'>Cash on delivery</label>
                    </div>
                </div>
              
                          
                    
                
                <p className='checkout-col ms-5 para'>Your personal data will be used to support your experience throughout this website,
                     to manage access to your account, and for other purposes described in our privacy policy.</p>

                <div className='row checkout-col m-0'>
                    <div className='col-12 mt-4'>
                        <button className='btn btn-primary mx-auto'>Place Order</button>
                    </div>
                </div>
            </div>
          
        </div>
    </div>

    </CommonSection>




        

        
    <CommonSection className={'shopSub-sec-4 pt-4 pb-4'}>
      <div className='container flex-column d-flex pt-4'>
         <div className='row '>
            <div className='col-1'>
                  <img src="/images/trophy 1.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>High Quality</h6>
               <p>crafted from top materials</p>
            </div>
            <div className='col-1'>
                  <img src="/images/guarantee.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>Warranty Protection</h6>
               <p>over 2 years</p>
            </div>
            <div className='col-1'>
                  <img src="/images/shipping.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>Free Shipping</h6>
               <p>Order over 150 $</p>
            </div>
            <div className='col-1'>
                  <img src="/images/customer-support.svg" alt=""></img>
            </div>
            <div className='col-2 mt-1'>
               <h6>24 / 7 Support</h6>
               <p>Dedicated support</p>
            </div>      
         </div>
      </div>
    </CommonSection>
    
    <CommonSection className="container home-sec-6">
            <div className="row align-items-center flex-column">
               <div className="col-12 d-flex justify-content-lg-center  justify-content-md-center  mt-5 mb-3">
                     <h1 >Travel<span>.</span> Capture<span>.</span> Share<span>.</span></h1>
               </div>
               <div className="col-12 d-flex  justify-content-lg-center  justify-content-md-center justify-content-sm-start  mb-2">
                  <p>The world is our playground, and every adventure with Travelista will be an unforgettable chapter in your travel story. </p>
               </div>
               <div className="col-12 d-flex  justify-content-lg-center  justify-content-md-center justify-content-sm-start  mt-2 mb-lg-5">
                  <button className="btn btn-primary me-2 mb-3 travel-btn">Plan your Travel</button>
                  <button className="btn btn-primary button">👋  Contact</button>
               </div>
            </div>
         </CommonSection>
    </>
  )
}

export default Checkout
