import React from 'react'
import "./base.css";
import CommonSection from '../common-componets/CommonSection';
import Slider from "react-slick";
import {  Link } from "react-router-dom";


const Shop = () => {

    const sec2Data = [
        {
           image_url: "/images/shop/sec-2/2.png",
           title: "Travel E-books"
        },
        {
            image_url: "/images/shop/sec-2/3.png",
          title: "Video Filters"
    
        },
        {
            image_url: "/images/shop/sec-2/1.png",
            title: "Google Maps"
         }
        ]
        const sec2mdData = [
            {
               image_url: "/images/shop/sec-2/2.png",
               title: "Travel E-books"
            },
            {
                image_url: "/images/shop/sec-2/3.png",
              title: "Video Filters"
        
            }
            ]
            const sec2md2Data = [
                
                {
                    image_url: "/images/shop/sec-2/1.png",
                    title: "Google Maps"
                 }
                ]
        const sec3Data = [
            {
               image_url: "/images/shop/sec-3/7.png",
               title: "Pobel bekurade",
               description: "Belingar närvaropeng",
               image_url2: "/images/star.svg",
               price: "￡10.00"
            },
            {
                image_url: "/images/shop/sec-3/3.png",
                title: "Pobel bekurade",
                description: "Belingar närvaropeng",
                image_url2: "/images/star.svg",
                price: "￡10.00"
             },
             {
                image_url: "/images/shop/sec-3/1.png",
                title: "Pobel bekurade",
                description: "Belingar närvaropeng",
                image_url2: "/images/star.svg",
                price: "￡10.00"
             },
             {
                image_url: "/images/shop/sec-3/2.png",
                title: "Pobel bekurade",
                description: "Belingar närvaropeng",
                image_url2: "/images/star.svg",
                price: "￡10.00"
             }
            ]
            const sec33Data = [
                {
                   image_url: "/images/shop/sec-3/3.png",
                   title: "Pobel bekurade",
                   description: "Belingar närvaropeng",
                   image_url2: "/images/star.svg",
                   price: "￡10.00"
                },
                {
                    image_url: "/images/shop/sec-3/4.png",
                    title: "Pobel bekurade",
                    description: "Belingar närvaropeng",
                    image_url2: "/images/star.svg",
                    price: "￡10.00"
                 },
                 {
                    image_url: "/images/shop/sec-3/5.png",
                    title: "Pobel bekurade",
                    description: "Belingar närvaropeng",
                    image_url2: "/images/star.svg",
                    price: "￡10.00"
                 },
                 {
                    image_url: "/images/shop/sec-3/6.png",
                    title: "Pobel bekurade",
                    description: "Belingar närvaropeng",
                    image_url2: "/images/star.svg",
                    price: "￡10.00"
                 }
                ]


                const sec4Data = [
                    {
                       image_url: "/images/shop/sec-4/1.png",
                        title: "Sedovobelt tår. "
                    },
                    {
                        image_url: "/images/shop/sec-4/2.png", 
                        title: "Sedovobelt tår. "
                     },
                     {
                        image_url: "/images/shop/sec-4/1.png",
                        title: "Sedovobelt tår. "
                     },
                     {
                        image_url: "/images/shop/sec-4/2.png",
                        title: "Sedovobelt tår. "
                     }
                    ]

                    const sec3mdData = [
                        {
                           image_url: "/images/shop/sec-3/7.png",
                           title: "Pobel bekurade",
                           description: "Belingar närvaropeng",
                           image_url2: "/images/star.svg",
                           price: "￡10.00"
                        },
                        {
                            image_url: "/images/shop/sec-3/3.png",
                            title: "Pobel bekurade",
                            description: "Belingar närvaropeng",
                            image_url2: "/images/star.svg",
                            price: "￡10.00"
                         }
                        ]
                        const sec33mdData = [
                            {
                               image_url: "/images/shop/sec-3/3.png",
                               title: "Pobel bekurade",
                               description: "Belingar närvaropeng",
                               image_url2: "/images/star.svg",
                               price: "￡10.00"
                            },
                            {
                                image_url: "/images/shop/sec-3/4.png",
                                title: "Pobel bekurade",
                                description: "Belingar närvaropeng",
                                image_url2: "/images/star.svg",
                                price: "￡7.000.000"
                             }
                            ]
                 
        
        const sec2SlideSettings = {
            dots: true,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 2000,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow:1
                }
            }
            ]
        };

    return (
    
      <>
   
        <div className="container-fluid blog-banner-container" style={{
            backgroundImage: 'url(/images/shop/shop.png)',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
         }} >
          <div className=" blog-banner container flex-column d-flex justify-content-center pt-5" >
               <div className="row mt-5">
                  <div className="col-lg-9 col-md-10 mt-5">
                    <h1>Terrakrati <span>stuprörspolitik plament</span></h1>
                     <p >Attitydinkontinens lasat persofi ifall planing. Rude. Vyrade hepubel: medelsiv antinat i  sude.
                      Resam nenas. Aledes plande påsm. Beling pugt doliga. Visam larat. Dore soräsamma en 
                      fanilogi fan astrospeling.   </p>
                  </div>
                </div>
          </div>
      </div>

      <CommonSection className={"shop pt-3 pb-5"}>
      <div className='container align-items-center justify-content-center shop-sec-2 mt-5 mb-lg-5'>
            <div className='row'>
                <div className='col-12 mb-5'>
                    <h2 >Browse The Range</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>
            <div className="d-lg-flex w-100 align-items-center justify-content-center hidden-md">
                {sec2Data.map((item, index) => {
                        return (
                        <div className="card">
                                    <div className="box">
                                        <img src={item.image_url} alt=''/>
                                        <h4 className='mt-4'>{item.title}</h4>
                                    </div>   
                                </div>
                        )
                    })}  
            </div>

            {/* medium screen */}
            <CommonSection className={" hidden-lg hidden"}>
            <div className="d-flex align-items-center justify-content-center">
                {sec2mdData.map((item, index) => {
                        return (
                        <div className="card">
                                    <div className="box">
                                        <img src={item.image_url} alt=''/>
                                        <h4 className='mt-4'>{item.title}</h4>
                                    </div>   
                                </div>
                        )
                    })}  
            </div>
            <div className="d-flex align-items-center justify-content-center mt-4">
                {sec2md2Data.map((item, index) => {
                        return (
                        <div className="card">
                                    <div className="box">
                                        <img src={item.image_url} alt=''/>
                                        <h4 className='mt-4'>{item.title}</h4>
                                    </div>   
                                </div>
                        )
                    })}  
            </div>
        </CommonSection>

        </div>
    </CommonSection>

    <CommonSection className={"shop pt-5 pb-5 hidden-md"}>
      <div className='container flex-column d-flex align-items-center justify-content-center shop-sec-3 mb-5'>
            <div className='row'>
                <div className='col-12 mb-5'>
                    <h2 >Featured Products</h2>
                </div>
            </div>
            <div className="d-lg-flex w-100 align-items-center justify-content-center">
                {sec3Data.map((item, index) => {
                    return (
                    
                <Link to="/shopSub2">
                <div className="card">
                            <div className="box">
                                <div className='tag1'>
                                            <h2>50%<br></br>OFF</h2>
                                </div>
                               
                                <img src={item.image_url} alt=''/>
                                <div className='title-box'>
                                    <h6 className='mt-3'>{item.title}</h6>
                                    <h5>{item.description}</h5>
                                    <img src={item.image_url2} alt='' className='star'/>
                                    <p>{item.price}</p>
                                </div>
                            </div>   
                            <div className='overlay'>
                                    <Link to="/cart"><button className='btn '>Add to cart</button></Link>
                                    <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                    <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                            </div>
                    </div>
                    </Link>

                        )
                    })} 
                </div> 
                <div className="d-lg-flex w-100 mt-lg-4 align-items-center justify-content-center">
                {sec33Data.map((item, index) => {
                    return (
                    <div className="card">
                        <div className="box">
                        <div className='tag2'>
                                    <h2>NEW</h2>
                                </div>
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt=''  className='star'/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                    <Link to="/cart"><button className='btn '>Add to cart</button></Link>
                                    <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                    <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                            </div>
                    </div>
                    )
                })}  
            </div>
            <div className='row  '>
                <div className='col-12'>
                    <Link to="/shopSub"><button className='btn btn-primary mx-auto'>Shop more</button></Link>
                </div>
            </div>
        </div>
    </CommonSection>


    {/* medium screen */}

    <CommonSection className={"shop pt-4 pb-5 hidden-lg hidden"}>
      <div className='container flex-column d-flex shop-sec-3 mt-5 mb-5'>
      <div className='row'>
                <div className='col-12 mb-3'>
                    <h2 >Featured Products</h2>
                </div>
            </div>
            <div className="d-flex w-100 mb-5 align-items-center justify-content-center">
                {sec3mdData.map((item, index) => {
                    return (
                    <div className="card" >
                        <div className="box">
                        <div className='tag1'>
                            <h2>50%<br></br>OFF</h2>
                                </div>
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className="star"/>
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                    <Link to="/cart"><button className='btn '>Add to cart</button></Link>
                                    <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                    <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                            </div>
                    </div>
                    )
                })} 
            </div> 
                <div className="d-flex w-100 mt-5 mb-5 align-items-center justify-content-center">
                {sec33mdData.map((item, index) => {
                    return (
                    <div className="card">
                        <div className="box">
                        <div className='tag2'>
                                    <h2>NEW</h2>
                                </div>
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' className="star" />
                                <p>{item.price}</p>
                            </div>
                        </div>   
                        <div className='overlay'>
                                    <Link to="/cart"><button className='btn '>Add to cart</button></Link>
                                    <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                    <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                            </div>
                    </div>
                    )
                })}  
            </div>
            <div className="d-flex w-100 mt-5 mb-5 hidden align-items-center justify-content-center">
                {sec3mdData.map((item, index) => {
                    return (
                    <div className="card">
                        <div className="box">
                        <div className='tag1'>
                                            <h2>50%<br></br>OFF</h2>
                                </div>
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt='' />
                                <p>{item.price}</p>
                            </div>
                        </div>
                        <div className='overlay'>
                                    <Link to="/cart"><button className='btn'>Add to cart</button></Link>
                                    <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                    <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                            </div>
                    </div>
                    )
                })}  
            </div>
            <div className="d-flex hidden w-100 mt-5 mb-5 align-items-center justify-content-center">
                {sec33mdData.map((item, index) => {
                    return (
                    <div className="card">
                        <div className="box">
                        <div className='tag2'>
                                    <h2>NEW</h2>
                                </div>
                            <img src={item.image_url} alt=''/>
                            <div className='title-box'>
                                <h6 className='mt-3'>{item.title}</h6>
                                <h5>{item.description}</h5>
                                <img src={item.image_url2} alt=''/>
                                <p>{item.price}</p>
                            </div>
                        </div> 
                        <div className='overlay'>
                                    <Link to="/cart"><button className='btn '>Add to cart</button></Link>
                                    <Link to="/shopSub2"><button className='btn mt-3 mb-3'>View detail</button></Link>
                                    <p><img src="/images/share.svg" alt=""></img> Share <span className='ms-2'><img src="/images/heart.svg" alt=""></img> Like</span></p>
                            </div> 
                    </div>
                    )
                })}  
            </div>
            <div className='row flex-column d-flex align-items-center justify-content-center mt-4'>
                <div className='col-12 mt-5'>
                    <button className='btn btn-primary mx-auto'>Shop more</button>
                </div>
            </div>
        </div>
    </CommonSection>

    <CommonSection className={"shop-sec-4 pb-5"}>
                <div className='container flex-column d-flex pt-3 pb-5'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 mt-5 '>
                            <h1 className='mt-4'>50+ Travel inspirations</h1>
                            <p>Kyligen kavis: i fön teras astromatisk, virade. Homohåsm atell saling, kvasimått i imåssa. </p>
                            <button className='btn btn-primary'>Read more</button>
                        </div>
                        <div className='col-lg-8 col-md-8 col-sm-12 '>
                            <Slider {...sec2SlideSettings} >
                                {sec4Data.map((item, index) => {
                                    return (
                                        <div key={index} className="slide">
                                            <div className="card" style={{ backgroundImage: `url(${item.image_url}`, backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
                                                <div className="box">
                                                </div>  
                                                <div className='content-box'>
                                                        <h4>{item.title}</h4>
                                                    </div> 
                                                <div className='yellow-box'>
                                                    <img src="/images/shop/sec-4/right-arrow.svg" alt=""></img>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </Slider>
                        </div>
                    </div>
                </div>
    </CommonSection>



    <CommonSection className="container home-sec-6">
            <div className="row align-items-center flex-column">
               <div className="col-12 d-flex justify-content-lg-center  justify-content-md-center  mt-5 mb-3">
                     <h1 >Travel<span>.</span> Capture<span>.</span> Share<span>.</span></h1>
               </div>
               <div className="col-12 d-flex  justify-content-lg-center  justify-content-md-center justify-content-sm-start  mb-2">
                  <p>The world is our playground, and every adventure with Travelista will be an unforgettable chapter in your travel story. </p>
               </div>
               <div className="col-12 d-flex  justify-content-lg-center  justify-content-md-center justify-content-sm-start  mt-2 mb-lg-5">
                  <button className="btn btn-primary me-2 mb-3 travel-btn">Plan your Travel</button>
                  <button className="btn btn-primary button">👋  Contact</button>
               </div>
            </div>
         </CommonSection>
    </>
  )
}

export default Shop
