// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-banner{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
.home-banner .banner.container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-banner .banner-content{
    width: 85%;
}
.home-banner .banner-content p{
    width: 70%;
}
.home-banner .pagination-btns{
    width: 15%;
}

.home-banner .pagination-button{
    margin-left: 0;
}


@media (min-width: 768px) and (max-width: 992px)
{
    .home-banner .banner-content{
        width: 85%;
    }
    .home-banner .pagination-btns{
        width: 45%;
    }
    .home-banner .banner .first-slide svg{
        right: 0;
    }
    .home-banner .banner-content p{
        width: 100%;
    }
    
}

@media (max-width: 576px)

{
    .home-banner .banner .first-slide svg {
        width: -moz-fit-content;
        width: fit-content;
    }
    .home-banner .banner-content p{
        width: 97%;
    }
    .home-banner .banner-content{
        width: 95%;
    }

}`, "",{"version":3,"sources":["webpack://./src/common-componets/Home/Banner.css"],"names":[],"mappings":"AAAA;IAII,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;EAC9B;AACF;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;AAClB;;;AAGA;;IAEI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;IACA;QACI,QAAQ;IACZ;IACA;QACI,WAAW;IACf;;AAEJ;;AAEA;;;IAGI;QACI,uBAAkB;QAAlB,kBAAkB;IACtB;IACA;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;;AAEJ","sourcesContent":[".home-banner{\r\n    -webkit-background-size: cover;\r\n    -moz-background-size: cover;\r\n    -o-background-size: cover;\r\n    background-size: cover;\r\n    background-position: center;\r\n    background-repeat: no-repeat;\r\n  }\r\n.home-banner .banner.container{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.home-banner .banner-content{\r\n    width: 85%;\r\n}\r\n.home-banner .banner-content p{\r\n    width: 70%;\r\n}\r\n.home-banner .pagination-btns{\r\n    width: 15%;\r\n}\r\n\r\n.home-banner .pagination-button{\r\n    margin-left: 0;\r\n}\r\n\r\n\r\n@media (min-width: 768px) and (max-width: 992px)\r\n{\r\n    .home-banner .banner-content{\r\n        width: 85%;\r\n    }\r\n    .home-banner .pagination-btns{\r\n        width: 45%;\r\n    }\r\n    .home-banner .banner .first-slide svg{\r\n        right: 0;\r\n    }\r\n    .home-banner .banner-content p{\r\n        width: 100%;\r\n    }\r\n    \r\n}\r\n\r\n@media (max-width: 576px)\r\n\r\n{\r\n    .home-banner .banner .first-slide svg {\r\n        width: fit-content;\r\n    }\r\n    .home-banner .banner-content p{\r\n        width: 97%;\r\n    }\r\n    .home-banner .banner-content{\r\n        width: 95%;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
