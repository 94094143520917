import React, { useState } from 'react'
import "./base.css";
import Slider from "react-slick";
import CommonSection from '../common-componets/CommonSection';
import {  Link } from "react-router-dom";
import ScrollToTop from '../common-componets/ScrollToTop';
import SecSlider from '../common-componets/SecSlider';
import DestinationEurope from '../common-componets/Destinations/Europe';


const Europe = () => {
   const scrollTop = () =>{
      window.scrollTo(0,0);
   }
   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }
   const sec3Data = [
      {
         image_url: "/images/europe/sec-3/1.png",
         title: "PARIS",
         description: "Eiffel Tower",
         link:"https://youtu.be/RksdZWD4Udg?si=AU5SpxylAzJwcuyu"
      },
      {
         image_url: "/images/europe/sec-3/2.png",
         title: "SWITZERLAND",
         description: "Paradise on the Earth",
         link:"https://youtu.be/VSHbyktxcU4?si=BNY9i_3EjbFEYEBA"
      }
   ]
   const sec3SlideSettings = {
      dots: false,
      infinite: true,
      autoplay: false,
      // autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 3
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:2
            }
         },
         {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
      ],
      arrows:false
   };


  const sec5Data = [
    {
       image_url: "/images/europe/sec-5/1.png",
       title: "LUXEMBOURG ",
       description: "Enjoy The Scenic Beauty ",
       link:"https://youtu.be/7r6IjDN2b_w?si=G084gnLuQX1qCfrN"
    },
    {
      image_url: "/images/europe/sec-5/2.png",
       title: "NORWAY ",
       description: "Heading To Most Dangerous Road ",
       link:"https://youtu.be/ElobS58_QSU?si=IJ_IeAVIlTepf6Do"
    },
    {
      image_url: "/images/europe/sec-5/3.png",
      title: "FRANCE ",
      description: "Exploring The Village ",
      link:"https://youtu.be/9h39dKGalzA?si=80jyD6G0zZheCr7B"
   },
      {
      image_url: "/images/europe/sec-5/4.png",
      title: "BELGIUM ",
      description: "Best Chocolate in The World ",
      link:"https://youtu.be/VRBhRRyMvCE?si=EvyHBdROKLIk2yYz"
   },
   {
      image_url: "/images/europe/sec-5/5.png",
      title: "NETHERLAND  ",
      description: "No Politics No Religion",
      link:"https://youtu.be/nOJLweP0SLc?si=a3sndE5PX05U7zkH"
   }

  ]

  // Custom previous and next arrows
   const PrevArrow = (props) => {
      const { className, onClick } = props;
      return (
      <div className={`custom-prev-arrow ${className}`} onClick={onClick}>
         Previous
      </div>
      );
   };

   const NextArrow = (props) => {
      const { className, onClick } = props;
      return (
      <div className={`custom-next-arrow ${className}`} onClick={onClick}>
         Next
      </div>
      );
   };

   
   const sec5SlideSettings = {
   variableWidth: true,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow:1
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ]
 };

  return (
    <>
         <DestinationEurope/>

         {/* SCROLL TOP */}
         {/* <ScrollToTop/> */}


    </>
  )
}

export default Europe
