import React, { useState } from 'react'
import "./base.css";
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import CommonSection from '../common-componets/CommonSection';
import ScrollToTop from '../common-componets/ScrollToTop';
import SecSlider from '../common-componets/SecSlider';
import DestinationLAmerica from '../common-componets/Destinations/America';

const America = () => {

   const[activeIndex,setActiveIndex]=useState(null);

   const imageHover = (index) => {

      setActiveIndex(index);
   }


  const sec5Data = [
    {
       image_url: "/images/america/12.png",
       title: "PERU",
       description: "UROS Tribal Floating Islands ",
       link:"https://youtu.be/bf_jA8iTRcc?si=y2xjZv0XsmExutt3 "
    },
    {
      image_url: "/images/america/8.png",
       title: "BOLIVIA ",
       description: "Peru-Bolivia Border Crossing ",
       link:"https://youtu.be/RJzS5xqQlq4?si=GUZxpH6y4gA0nRaw "
    },
    {
      image_url: "/images/america/9.png",
      title: "RIO DE JANEIRO ",
      description: "To the Land of Celebrations ",
      link:"https://youtu.be/c7ut9SkI1oo?si=r8xpHU5T5LpQspek "
   },
      {
      image_url: "/images/america/10.png",
      title: "USHUAIA ",
      description: "End of the World ",
      link:"https://youtu.be/BDx5EEuj2Us?si=mOqWE18mz2wOBffp "
   },
   {
      image_url: "/images/america/11.png",
      title: "BOLIVIA  ",
      description: "Gold Smuggling Routes ",
      link:"https://youtu.be/WIMu76E0Ynk?si=FdGrtOE2d-htexEu "
   }

  ]
  const scrollTop = () =>{
   window.scrollTo(0,0);
}
   const sec3Data = [
      {
         image_url: "/images/america/1.png",
         title: "MACHU PICCHU",
         description: "Near Death Experience",
         link:"https://youtu.be/LhM6Z8V_ZPc?si=Uj0eVU182_bzfaJG"
      },
      {
         image_url: "/images/america/2.png",
         title: "AMAZON",
         description: "Anaconda Hunting",
         link:"https://youtu.be/0Z0pX4MMf70?si=4wT8JIvdUyWo-WfA"
      }
   ]
   const sec3SlideSettings = {
      dots: false,
      infinite: true,
      autoplay: false,
      // autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 3
            }
         },
         {
            breakpoint: 768,
            settings: {
               slidesToShow:2
            }
         },
         {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
      ],
      arrows:false
   };

   // Custom previous and next arrows
   const PrevArrow = (props) => {
      const { className, onClick } = props;
      return (
        <div className={`custom-prev-arrow ${className}`} onClick={onClick}>
          Previous
        </div>
      );
    };
    
    const NextArrow = (props) => {
      const { className, onClick } = props;
      return (
        <div className={`custom-next-arrow ${className}`} onClick={onClick}>
          Next
        </div>
      );
    };
      
   const sec5SlideSettings = {
   prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    variableWidth:true,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
       {
          breakpoint: 992,
          settings: {
             slidesToShow: 1
          }
       },
       {
          breakpoint: 768,
          settings: {
             slidesToShow:1
          }
       },
       {
         breakpoint: 576,
         settings: {
            slidesToShow:1
         }
      }
    ]
 };

  return (
    <>
         <DestinationLAmerica/>
         {/* SCROLL TOP */}
            {/* <ScrollToTop /> */}


    </>
  )
}

export default America
