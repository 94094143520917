import React,{useState} from 'react'
import './HomeGallery.css'
import Slider from "react-slick";
import {  Link } from "react-router-dom";



const HomeGallery = (props) => {
  const scrollTop = () =>{
    window.scrollTo(0,0);
  }
  // IMAGE POPUP
  const [popupImage, setPopupImage] = useState(false);

  const handleImageClick = (url) => {
      setPopupImage(true);
      setSelectedImageUrl(url);
  };

  const handleClosePopup = () => {
      setPopupImage(false);
      setSelectedImageUrl(null);
  };

  const[ selectedImageUrl, setSelectedImageUrl ]= useState(null)
  

  return (
    
    <>
        <div className="row d-flex me-lg-auto ms-lg-auto mb-5">
              <div className="col-12">
                  <h2 className="yellow-line">JOURNEY <br/> THROUGH THE LENS</h2>
              </div>
          </div> 
          {/* WEB */}
          <div className="container  d-flex w-100 hidden hidden-md">
               {props.secData.map((item, index) => {
                     return (<div className="card" onClick={ ()=> handleImageClick(item.image)}   >
                              <div className="image-container" style={{ backgroundImage: `url(${item.image})` , backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
                              <div className="box">
                                <div>
                                <Link to ="/gallery" onClick={scrollTop}><h4>{item.title}</h4></Link>
                                <div className="line"></div>
                                </div>
                                
                              </div> 
                              
                           </div>
                           </div>
                     )
               })}  
            </div>
            
          <div className={ popupImage ? "img-popup show hidden" : "img-popup"}>
              <img src={selectedImageUrl} />
              <svg onClick={handleClosePopup} class="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
              </svg>
          </div>
          
          {/* TAB AND MOBILE */}
          <div className="d-flex w-100 hidden-lg ">
              <Slider {...props.secSlideSettings} className="product-card-slider p-0">
                {props.secData.map((item, index) => {
                    return (
                      <div key={index} className="slide">
                          <div className="card" onClick={ ()=> handleImageClick(item.image)} style={{ backgroundImage: `url(${item.image_url}`, backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
                            <div className="box">
                              <Link to ="/gallery" onClick={scrollTop}><h4>{item.title}</h4></Link>
                              {item.title && <div className="line"></div>}
                            </div>   
                          </div>
                      </div> 
                    )
                })}

              </Slider>
          </div> 
    </>
  );
};

export default HomeGallery;