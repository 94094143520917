import React from 'react';
import './PaginationButton.css';

const PaginationButton = ({ index, currentIndex, totalSlides, onClick }) => {
  const handleClick = () => {
    onClick(index);
  };

  const zfillIndex = (index) => {
    return index < 9 ? `0${index + 1}` : index + 1;
  };

  const getAdjustedIndex = (index) => {
    if (index < 0) return totalSlides - 1;
    if (index >= totalSlides) return 0;
    return index;
  };

  const prevIndex = getAdjustedIndex(currentIndex - 1);
  const nextIndex = getAdjustedIndex(currentIndex + 1);

  const shouldRender = [prevIndex, currentIndex, nextIndex].includes(index);

  return shouldRender ? (
    <div className="pagination-button" onClick={handleClick}>
      <span className={`number ${index === currentIndex ? 'active' : ''}`}>
        {zfillIndex(index)}
      </span>
      {index === currentIndex && <span className="tab-line"></span>}
    </div>
  ) : null;
};

export default PaginationButton;
