import React, { useState, useEffect } from 'react'
import "./base.css";
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import CommonSection from '../common-componets/CommonSection';
import ScrollToTop from '../common-componets/ScrollToTop';
import SecSlider from '../common-componets/SecSlider';
import BlogSlider from '../common-componets/Home/HomeBlogSlider';

const About = () => {

    const sec2Data = [
        {
           image_url: "/images/about/sec-5/1.png",
           title: "Vanlife In India ",
           link:"https://www.youtube.com/playlist?list=PLFPtTvh_z4FN4vuOtBhD5WfV2eRJuxC9r "
        },
        {
           image_url: "/images/about/sec-5/2.png",
           title: "Africa ",
           link:"https://www.youtube.com/playlist?list=PLFPtTvh_z4FPTCfVyL7dMzE3izF1rfLoi "
        },
        {
           image_url: "/images/about/sec-5/3.png",
           title: "Brazil",
           link:"https://www.youtube.com/playlist?list=PLFPtTvh_z4FOmRErgHLVEjiaxUzmTo4Hl "
        },
        {
           image_url: "/images/about/sec-5/4.png",
           title: "Antarctica",
           link:"https://www.youtube.com/playlist?list=PLFPtTvh_z4FNcAjSOJxZynzPbPAJ9HBoj "
        },
        {
           image_url: "/images/about/sec-5/5.png",
           title: "Argentina",
           link:"https://www.youtube.com/playlist?list=PLFPtTvh_z4FNaawZOSYtY_vBq_6Am9LvC "
        },
       
     ]
     const scrollTop = () =>{
         window.scrollTo(0,0);
      }
      const sec2SlideSettings = {
         dots: false,
         infinite: true,
         autoplay: false,
         autoplaySpeed: 2000,
         speed: 1000,
         slidesToShow: 3,
         slidesToScroll: 2,
         cssEase: 'ease',
         variableWidth: true,
         arrows:true,
         // prevArrow: <PrevArrow />,
         // nextArrow: <NextArrow />,
         responsive: [
            {
               breakpoint: 992,
               settings: {
                  slidesToShow: 1
               }
            },
            {
               breakpoint: 768,
               settings: {
                  slidesToShow:1
               }
            },
            {
               breakpoint: 576,
               settings: {
                  slidesToShow:1
               }
            }
         ]
      };
       //  BANNER EFFECT
      const [scrollScale, setScrollScale] = useState(1);

      useEffect(() => {
      const handleScroll = () => {
         const scrollPosition = window.scrollY;
         const maxZoomScroll = 400;

         const scale = 1 + (scrollPosition / maxZoomScroll) * 0.1;
         setScrollScale(scale);
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
      }, []);
      // useEffect(() => {
      //    const banner = document.querySelector('.about-banner-container ');
      //    const initialHeight = banner.offsetHeight;
      //    const scaledHeight = initialHeight * scrollScale;
      //    const translateY = scaledHeight - initialHeight;
       
      //    banner.style.backgroundSize = `${100 * scrollScale}%`;
      //    banner.style.backgroundPosition = `center bottom -${translateY}px`;
      //  }, [scrollScale]);


    return (
        <>
        <div class="parallax-container">
            <div class="container-fluid about-banner-container">
               <div class="banner container position-relative d-flex justify-content-end">
                  <img src="/images/about/parallax.png" class="parallax-img" />
                  <div class="text-overlay">
                        <h1>SAILING FROM<br/> MERCHANT NAVY TO CONTENT CREATOR</h1>
                  </div>
               </div>
            </div>
         </div>
        
        <div className='container-fluid  about-section-2'>
            <div className='container'>
               <div className='col-lg-12 col-md-12 col-sm-12'>
                  <p>Welcome to the vibrant world of <Link to="https://www.youtube.com/@Travelistabysantos" target='_blank'><span className='bold'> Travelista by santos</span></Link>, where wanderlust meets adventure, and every journey is a captivating story waiting to be told. Meet Santo Thomas, fondly known as "Santappan," the brave soul behind the lens capturing the essence of exploration and discovery.<br></br><br></br>
                  He is a passionate traveller from Kerala (India). He sailed through over 24 countries during his Merchant Navy tenure, and when his sea voyages ended, his thirst for travel only grew stronger. As a YouTube vlogger, he started his journey with a bicycle, roamed in and around Thrissur (his native place) with his GoPro camera, and shot the places, which made him viral. 
                  The channel grew quickly, earning subscribers and making him think of an all-India Travel. 
                  </p>
               </div>
               <div className="col-12 mt-3">
               <p>He started a van life similar to European or Western van life culture and finished an All-India trip with his two friends, Binoy and Lisshoy. They altered a Force Tempo traveller into a travelling home and named him Kapitan -" the ship captain". They have covered most of the places in India's land with their beloved Kapitan and have done videos about India's villages, peoples, landscapes, rivers, etc. The Ministry of Defense of India also permitted them to shoot several episodes with the Indian Army (Border Security Force). It was a great honour for them when team Travelista touched the Line of Control (LOC) in Pakistan, which sets us apart from other Indian travel vloggers.<br></br><br></br>
                     With an insatiable thirst for adventure, he has traversed the globe, weaving through the diverse landscapes of Africa, the timeless charm of Europe, the icy wonders of Antarctica, the mystique of the Middle East, the vibrant hues of Asia, and the rich tapestries of Latin America. Each expedition is not merely a destination, but an immersive experience etched into the soul of Travelista.<br></br><br></br>
                     What sets him apart is not just his destinations but his dedication to delivering unparalleled quality content to his audience. Every video, every narrative, and every frame is a testament to his commitment to showcasing the world's beauty, culture, and hidden treasures. His lens doesn't just capture landscapes; it tells the stories of people, their traditions, and the profound moments that define each place.<br></br><br></br>
                     Beyond being a travel vlogger, Santappan is an ambassador of curiosity, encouraging viewers to embrace the unknown and step outside their comfort zones. His infectious enthusiasm for exploration inspires countless souls to embark on their adventures, armed with the belief that the world is a vast, magnificent canvas waiting to be explored.</p>
               </div>

            </div>
        </div>

        {/* CARD */}
        <div className=" home-sec-5" style={{
               backgroundImage: 'url(/images/Blog-bg/about-blog-bg.png)',
               marginBottom: '1rem',
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               WebkitBackgroundSize: "cover",
               MozBackgroundSize: "cover",
               OBackgroundSize: "cover",
               paddingTop:"6rem"
            
            }}>

            <div className="slide-container">
               <div className="row sec-title mt-5 mb-5 hidden-lg hidden-md" >
                  <div className="col-12">
                     <h2 className="yellow-line mt-5 text-dark" >EXPLORE<br/>OUR TRAVEL BLOGS</h2>
                  </div>
               </div>
               <BlogSlider secData={sec2Data} secTitle={(
                     <> EXPLORE <br className="web-view-break"/> OUR TRAVEL BLOGS </>
                  )} 
                  secSettings={sec2SlideSettings}></BlogSlider>
            </div>

           
           
         </div>
            
          
         {/* SCROLL TOP */}
         {/* <ScrollToTop/> */}


        </>
    )
}

export default About