import React from "react";
import "./Footer.css";
import {  Link } from "react-router-dom";
import { useNavigate  } from 'react-router-dom';

const Footer = () => {
  let navigate = useNavigate(); 
  var LOGOURL;

  if(window.innerWidth>768){
      LOGOURL="/images/zog-logo.svg"
  }
  else{
    
    LOGOURL="/images/zog-logo-mobile-footer.svg"
  }

  const scrollTop = () =>{
    window.scrollTo(0,0);
 }


  return (
    <div className="footer container-fluid">
      
      <div className="container-fluid">
        {/* WEB */}
        <div className="row justify-content-between text-white hidden-md hidden-lg tail-line">
          
        </div>
         <div className="row justify-content-between  tail hidden-md">
                <div className="col-lg-3 img-box">
                    <img src="/images/banner-logo.svg"></img>
                </div>
                <div className="col-lg-6 cr-box justify-content-end align-items-right"><p>© 2023 Travelista . All Rights Reserved. <Link  to="https://zoggstudio.com/" target="_blank"> <span className="hide">Powered by ZOG Global</span> </Link></p>
                  <h6 className="mb-3">Powered by ZOG Global</h6>
                </div>
                <div className="col-lg-3 col-md-12  d-flex justify-content-end align-items-right footer-sec">
                            <div className="media">
                                <Link to="https://www.youtube.com/@Travelistabysantos" target="_blank"><button><img src="/images/youtube.svg" alt="youtube"></img></button></Link>
                                <Link to="https://www.facebook.com/travelistabysantos" target="_blank"><button> <img src="/images/facebook.svg" alt="facebook"></img></button></Link>
                                <Link to="https://www.instagram.com/travelista_by_santos/" target="_blank"><button><img src="/images/instagram.svg" alt="instagram"></img></button></Link>
                                <Link to="" target="_blank"><button><img src="/images/tiktok.svg" alt="twitter"></img></button></Link>
                            </div>
                </div>
            
          </div>
         
              
        
        {/* TAB */}
        
        <div className="mt-4 tail hidden hidden-lg d-none d-md-block">

                <div className="img-box">
                    <img src="/images/banner-logo.svg"></img>
                </div>
                <div className="col-lg-3 col-md-12  d-flex justify-content-end align-items-center footer-sec">
                            <div className="media">
                                <Link to="https://www.youtube.com/@Travelistabysantos" target="_blank"><button><img src="/images/youtube.svg" alt="youtube"></img></button></Link>
                                <Link to="https://www.facebook.com/travelistabysantos" target="_blank"><button> <img src="/images/facebook.svg" alt="facebook"></img></button></Link>
                                <Link to="https://www.instagram.com/travelista_by_santos/" target="_blank"><button><img src="/images/instagram.svg" alt="instagram"></img></button></Link>
                                <Link to="" target="_blank"><button><img src="/images/tiktok.svg" alt="twitter"></img></button></Link>
                            </div>
                </div>
                <div className="cr-box"><p>© 2023 Travelista . All Rights Reserved. <Link  to="https://zoggstudio.com/" target="_blank">Powered by ZOG Global </Link> </p>
                </div>
                {/* <button className="btn  btn-borderless ms-auto me-auto mt-3 pb-5" onClick={scrollTop} id="page-down-btn" style={{transform: "rotate(180deg)"}}>
                  <img src="/images/banner-down-arrow.svg" alt="up"></img>
                </button> */}

          </div>
        
      </div>
    </div>
  );
};

export default Footer;