import {React,useState} from 'react'
import { imageUrls } from "../constants/galleryImages";
import Slider from "react-slick";
import "./GallerySlider.css"

import Masonry,{ResponsiveMasonry} from 'react-responsive-masonry';


const GallerySlider = () => {
    

    const sec2Data = imageUrls
    const images = imageUrls.asia

    const [currentSlide, setCurrentSlide] = useState(0);

    const sec2SlideSettings = {
        dots: false,
        arrows:true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow:1
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow:1
            }
        }
        ],
        afterChange: (current) => {
            setCurrentSlide(current);
        }
    };

    // IMAGE POPUP
   const [popupImage, setPopupImage] = useState(false);

   const handleImageClick = (url) => {
    console.log(url)
      setPopupImage(true);
      setSelectedImageUrl(url);
   };

   const handleClosePopup = () => {
      setPopupImage(false);
   };

   const[ selectedImageUrl, setSelectedImageUrl ]= useState(null)

   const flattenedImages = images.flat();
   const columnCountBreakPoints = { 350: 1, 750: 1, 900: 3 }


  return (
    <div>
        <div className='container gallery-sec-2 align-items-center justify-content-center '>
        {/* <div className="slider-info">
            <p><span className='current-no'>{currentSlide + 1}</span ><span className='total-no'>/{images.length}</span> </p>
        </div>   */}
        {/* <div className="row mb-2 img-video">
            <p><span className='img'>IMAGES </span ><span className='video'>/ VIDEOS    </span> </p>
        </div>   */}
        
        <div className='row gallery-imgs'>
            <ResponsiveMasonry columnsCountBreakPoints={columnCountBreakPoints}>
                <Masonry gutter='20px'>
                {flattenedImages.map((image, i) => (
                    <img
                        key={i}
                        onClick={() => handleImageClick(image)}
                        src={image}
                        style={{ width: '100%', display: 'block' }}
                        alt={`Image ${i}`}
                    />
                ))}
                </Masonry>
            </ResponsiveMasonry>
        </div>

        

        <div className={ popupImage ? "img-popup show hidden" : "img-popup"}>
                <img src={selectedImageUrl} />
            
            <svg onClick={handleClosePopup} class="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            </svg>
        </div>
        
      </div>
    </div>
  )
}

export default GallerySlider