export const navArray = [
    
   
    
    
    {
    "label": "Destinations", "path": "/destinations", "subMenu": [

        { "label": "Asia", "path": "/asia", "subSubMenu":[
            // { "label": "Malaysia", "path": "/nopage" },
            // { "label": "Georgia", "path": "/nopage" },
            // { "label": "Bali", "path": "/nopage" },
            // { "label": "Maldives", "path": "/nopage" },
            // { "label": "India", "path": "/nopage" },
            // { "label": "Cambodia", "path": "/nopage" },
            // { "label": "Singapore", "path": "/nopage" },
            // { "label": "Vietnam", "path": "/nopage" }


        ]},
        { "label": "Africa", "path": "/africa" },
        { "label": "Europe", "path": "/europe" },
        { "label": "Middle East", "path": "/middleEast" },
        { "label": "Latin America", "path": "/america" },

    ]
},
{
    "label": "Blog", "path": "/blog", "subMenu": [

    //     { "label": "ALL CATEGORIES", "path": "/nopage",
    
    //      "subSubMenu":[
    //         { "label": "Travel Tips", "path": "/nopage" },
    //         { "label": "Budget Travel", "path": "/nopage" },
    //         { "label": "Sustainable Trips", "path": "/nopage" },
    //         { "label": "Tech", "path": "/nopage/" },
    //         { "label": "Social Media", "path": "/nopage" },
    //         { "label": "Lifestyle", "path": "/nopage" },
    //         { "label": "Travel Insurance", "path": "/nopage" },
    //         { "label": "Adventure", "path": "/nopage" },
    //     ]
    
    
    
    // },

    ]
},
{
    "label": "Gallery", "path": "/gallery", subMenu:[],
},
// {
//     "label": "PLANNING", "path": "/nopage", "subMenu": [
//         { "label": "PACKAGE #1", "path": "/nopage" },
//         { "label": "PACKAGE #2", "path": "/nopage" },
//         { "label": 'PACKAGE #3', "path": "/nopage" },
//         { "label": "PACKAGE #4", "path": "/nopage" },
        
//     ]
// },
{
    "label": "About Us", "path": "/about", "subMenu": [ ]
},

{
    "label": "Ad & Media", "path": "/ad&media", "subMenu": [ ]
},
{
    "label": "Contact Us", "path": "/contact", "subMenu": [ ]
}
// {
//     "label": "SHOP", "path": "/shop", "subMenu": [
//     ]
// }

]
