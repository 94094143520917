import React from 'react'
import Slider from "react-slick";
import {  Link } from "react-router-dom";
import "./HomeBlogSlider.css"


const BlogSlider = (props) => {
    const scrollTop = () =>{
        window.scrollTo(0,0);
     }
    const firstSlideWidth = window.innerWidth < 576 ? 0 : (window.innerWidth < 992 ? 460 : 720);
  return (
    <div className=' bloghome slider-outer'>
    
      <Slider {...props.secSettings} className='slide'>
            <div className="row sec-title mt-5 mb-5 lg-title" style={{ width: firstSlideWidth }}>
               <div className="col-12">
                  <h2 className="yellow-line mt-5" >{props.secTitle}</h2>
               </div>
            </div>
            
        {props.secData.map((item, index) => (
            
            <div key={index} className="slide" style={{ width: 300 }}>
                <Link to={item.link || '/blogRead'} target={item.link ? '_blank' : '_self'} onClick={item.link ? undefined : scrollTop}>
                    <div className="blogcard " >
                        <img src={item.image_url} alt='' class="blogcard-img-top" />
                        <div class="blogcard-body">
                        {item.text ? (
                              <>
                                  <h5 className="blogcard-text">{item.text}</h5>
                                  <p className="blogcard-text">{item.month} {item.date}, {item.year}</p>
                              </>
                          ) : (
                              <>
                                  <h5 className="blogcard-title">{item.title}</h5>
                                  <p className="blogcard-desc">{item.description} </p>
                              </>
                          )}
                        </div>  
                    </div>
                </Link>
            </div> 
            
        ))}
      </Slider>
    </div>
  )
}

export default BlogSlider;