import React, {useEffect, useState} from 'react'
import CommonSection from '../common-componets/CommonSection';
import './Contact.css'
const Contact = () => {

  const scrollTop = () =>{
    window.scrollTo(0,0);
  }
  const [scrollOpacity, setScrollOpacity] = useState(1);
  const [scrollScale, setScrollScale] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxOpacityScroll = 400; 
      const maxZoomScroll = 800; 

      // Calculate opacity based on scroll position
      const opacity = 1 - (scrollPosition / maxOpacityScroll);
      setScrollOpacity(opacity < 0 ? 0 : opacity);

      // Calculate scale based on scroll position
      const scale = 1 + (scrollPosition / maxZoomScroll) * 0.2; 
      setScrollScale(scale);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
    useEffect(() => {
      const images = document.querySelectorAll('.contact-section-1 img');
      images.forEach(img => {
        img.style.opacity = scrollOpacity;
        img.style.transform = `scale(${scrollScale})`;
      });
    }, [scrollOpacity, scrollScale]);
    
 
  return (
    <> 
        <div class="contact-section-1 img_container">
          
          <div class="banner-content" >
            <h1 >REACH OUT &<br/><span>EXPLORE</span> TOGETHER</h1>
            <p className='text-white text-align-justify'>Join us for travel tips, collaboration opportunities, and engaging with fellow travel enthusiasts. We're excited to connect!</p>
          </div> 
          <img  className='hidden hidden-md' src="/images/contact/banner.png" alt="Banner" />
          <img  className='hidden-lg hidden' src="/images/contact/banner-tab.png" alt="Banner" />
          <img  className='hidden-lg hidden-md' src="/images/contact/banner-sm.png" alt="Banner" />
      </div>
      <section class="contact-section-2 content-wrapper">
        <div className=" form-section align-items-center flex-column d-flex ">
              <form  id="enquiryForm" >
                  <h1 className='align-items-left'>Start an enquiry with us</h1>
                <div className="mb-lg-4">
                  <select className="form-select form-control-lg mb-3" id="catSelect" name="category"
                    aria-label="Default select example" >
                    <option selected>Select Category</option>
                    <option value="1">Guided Tours</option>
                    <option value="1">Ad & Media</option>
                    <option value="2">Collabs</option>
                    <option value="3">Other</option>
                  </select>

                </div>
                <div className="contact-form mb-lg-4">
                  {/* <label for="Name" className="form-label">Name</label> */}
                  <div className="row form-row">
                    <div className="col-lg-6 col-md-6 col-12 mb-2">
                      <input type="text" placeholder="First Name" className="form-control form-control-lg fname" id="firstName" name="firstName"  
                        aria-describedby="nameHelp" maxlength="20" />
                        <p className="text-danger " id="firstName-errorMessage">Please enter a valid First Name without special characters or numbers.</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mb-2">
                      <input type="text" placeholder="Last Name" className="form-control form-control-lg lname" id="lastName"
                        name="lastName" aria-describedby="nameHelp" maxlength="20"   />
                        <p className="text-danger " id="lastName-errorMessage">Please enter a valid Last Name without special characters or numbers.</p>

                      <div className="invalid-feedback">
                        Please type a Name.
                      </div>
                    </div>
                  </div>

                </div>
                <div className="mb-lg-4">
                  {/* <label for="emai" className="form-label">Email</label> */}
                  <input type="email" placeholder='Email'  className="form-control form-control-lg" id="email" name="email"
                    aria-describedby="emailHelp" maxlength="100"  />
                    <p className="text-danger " id="email-errorMessage">Please enter a valid Email.</p>

                </div>
                <div className="mb-lg-4">
                  {/* <label for="phone" className="form-label">Phone Number</label> */}
                  <input placeholder='Phone Number' className="form-control form-control-lg" id="phone" type="tel" name="phone" maxlength="10"  />
                  <p className="text-danger " id="phone-errorMessage">Please enter a valid Phone Number with numbers only.</p>
                </div>
                

                <div className="mb-4">
                  {/* <label for="exampleFormControlTextarea1"  className="form-label">Details</label> */}
                  <textarea placeholder='Details' className="form-control form-control-lg" id="exampleFormControlTextarea1" maxlength="500"  rows="4" 
                    name="details"></textarea>
                </div>
                <button type="" className="other01-btn" > Send</button>
              </form>
          </div>
      </section>
    </>
  );
}

export default Contact

