export const videoUrls = {
    "asia": [
        [
            "/images/gallery/Travelista Files/20210408_095917_02.mp4",
            "/images/gallery/Travelista Files/20210408_160209.mp4",
            "/images/gallery/Travelista Files/20210416_152104.mp4",
            "/images/gallery/Travelista Files/20210530_144053.mp4",
            "/images/gallery/Travelista Files/20210603_142638.mp4",
            "/images/gallery/Travelista Files/20210610_134704.mp4",
            "/images/gallery/Travelista Files/20230203_171204.mp4",
            "/images/gallery/Travelista Files/20230323_130022.mp4",
            "/images/gallery/Travelista Files/20230622_133908.mp4"
        ],
        [
            "/images/gallery/Travelista Files/20230701_095738.mp4",
            "/images/gallery/Travelista Files/IMG_4133.MOV",
            "/images/gallery/Travelista Files/IMG_8012.MOV"
        ]
    ]
}