export const BannerData = [
    {
    image_url: "/images/app-home/banner/1.png",
    image_url_mobile: "/images/app-home/banner/1-sm.png",
    image_url_tab: "/images/app-home/banner/1-md.png",
    title: "TRAVEL WITH ",
    title1:"",
    title2:"",
    title_logo:"/images/app-home/logo-2.svg",
    description: "Come travel with us! Explore the world alongside",
    bold:" Travelista by Santos",
    description1:", your friendly guide to amazing adventures!",
    frame:"/images/app-home/01.png",
    },
    {
    image_url: "/images/app-home/banner/2.png",
    image_url_tab: "/images/app-home/banner/2-md.png",
    image_url_mobile: "/images/app-home/banner/2-sm.png",
    title: `CREATIVE `,
    title2:"AD & MEDIA",
    description: "Wow your audience with awesome ads and amazing media magic from team Travelista! Let us help your brand shine with our knack for creating eye-popping visuals.",
    frame:"/images/app-home/02.png",
 },
    {
    image_url: "/images/app-home/banner/3.png",
    image_url_tab: "/images/app-home/banner/3-md.png",
    image_url_mobile: "/images/app-home/banner/3-sm.png",
    title: "ULTIMATE ",
    title1:"",
    title2:"TRAVEL COMPANION",
    title3:"",
    description: "Join our fun-loving community as we discover stunning places, meet incredible people, and make unforgettable memories.",
    frame:"/images/app-home/03.png",
 },
 {
    image_url: "/images/app-home/banner/4.png",
    image_url_tab: "/images/app-home/banner/4-md.png",
    image_url_mobile: "/images/app-home/banner/4-sm.png",
    title: "Travel in",
    title2:"Asia with Travelista",
    description: "",
    frame:"/images/app-home/04.png",
 },
 {
    image_url: "/images/app-home/banner/5.png",
    image_url_tab: "/images/app-home/banner/5-md.png",
    image_url_mobile: "/images/app-home/banner/5-sm.png",
    title: "Travel in",
    title2:"Africa with Travelista",
    description: "",
    frame:"/images/app-home/04.png",
 },
 {
    image_url: "/images/app-home/banner/6.png",
    image_url_tab: "/images/app-home/banner/6-md.png",
    image_url_mobile: "/images/app-home/banner/6-sm.png",
    title: "Travel in",
    title2:"Europe with Travelista",
    description: "",
    frame:"/images/app-home/04.png",
 },
 {
    image_url: "/images/app-home/banner/7.png",
    image_url_tab: "/images/app-home/banner/7-md.png",
    image_url_mobile: "/images/app-home/banner/7-sm.png",
    title: "Travel in",
    title2:" with Travelista",
    destination:"Middle East",
    description: "",
    frame:"/images/app-home/04.png",
 },
 {
    image_url: "/images/app-home/banner/8.png",
    image_url_tab: "/images/app-home/banner/8-md.png",
    image_url_mobile: "/images/app-home/banner/8-sm.png",
    title: "Travel in",
    title2:"with Travelista",
    destination:"Latin America",
    description: "",
    frame:"/images/app-home/04.png",
 }
 ]
