import React from 'react'

const ScrollToTop = () => {


    const scrollTop = () =>{
        window.scrollTo(0,0);
        }
    return (
        <div class="container scroll-top">
        <div class="row">
            <div class="col">
            </div>
            <div class="col-auto ">
                <button className="btn  btn-borderless ms-auto me-auto mt-3 pb-5 scroll-top-btn" onClick={scrollTop}  >
                <img src="/images/arrow-top.svg" alt="up"></img>
                </button>
                <span className="">SCROLL TO TOP</span>
            </div>
        </div>
    </div>
    )
}

export default ScrollToTop